import { useCatalogColumnData } from "@/hooks";
import {
  AutoComplete,
  Box,
  SelectPaper,
  StyledTextField,
  Text,
  ValueInput,
} from "@/includes";
import { Skeleton, Stack, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { distinct } from "./helpers";
import useInputCatalogColumnData from "./hooks/useInputCatalogColumnData";
import { ICatalogColumnData } from "./types";
import { take } from "lodash";
import { getTraduction } from "@/utils/helpers/traductions";

const CatalogColumnData: React.FC<ICatalogColumnData> = ({
  columnName,
  handleChangeValue,
  value,
  outputParamType,
  entryParamType = "",
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { values, status, loading } = useCatalogColumnData(
    columnName ?? outputParamType,
    entryParamType
  );
  const { inputValue, setInputValue } = useInputCatalogColumnData();

  if (loading)
    return (
      <Skeleton
        width={250}
        height={40}
        animation="wave"
        variant="rounded"
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
      />
    );

  if (
    status === "error" ||
    ["number", "float", "boolean"].includes(outputParamType?.toLowerCase()) ||
    (status !== "loading" && values.length === 0)
  ) {
    return (
      <Box sx={{ maxWidth: 300 }}>
        <ValueInput
          type={outputParamType?.toLowerCase()}
          value={value}
          handleChangeValue={handleChangeValue}
        />
      </Box>
    );
  }

  return (
    <Stack sx={{ width: 320 }} alignItems="flex-start">
      {outputParamType === "array" && (
        <Text variant="caption" customStyle={{ mb: 1 }}>
          {t("paste_list_value")}
        </Text>
      )}
      <AutoComplete
        loading={loading}
        size="small"
        multiple={outputParamType === "array"}
        sx={{ minWidth: 320, maxWidth: 345 }}
        disableClearable
        freeSolo
        disableCloseOnSelect={outputParamType === "array"}
        value={value}
        limitTags={1}
        inputValue={inputValue}
        onInputChange={(e, val) => setInputValue(val)}
        options={take(
          values.filter((value) => {
            return (value?.toString() ?? "")?.includes(inputValue ?? "");
          }),
          100
        )}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label={getTraduction(t, columnName)}
            name={getTraduction(t, columnName)}
            onChange={(e: any) => {
              setInputValue(e.target.value);
              if (outputParamType !== "array") {
                handleChangeValue(e.target.value);
              } else {
                if (e.nativeEvent.inputType !== "insertText") {
                  if (e.target.value.includes(";")) {
                    const newValues = e.target.value.split(";");
                    handleChangeValue(
                      [
                        ...value,
                        ...newValues.filter((val: any) => values.includes(val)),
                      ].filter(distinct)
                    );
                    setInputValue("");
                  }
                  if (values.includes(e.target.value)) {
                    handleChangeValue(
                      [...value, e.target.value].filter(distinct)
                    );
                    setInputValue("");
                  }
                }
              }
            }}
          />
        )}
        onChange={(_: React.ChangeEvent<any>, newValue: any) =>
          handleChangeValue(newValue)
        }
        componentsProps={{ popper: { style: { width: "fit-content" } } }}
        PaperComponent={SelectPaper}
      />
    </Stack>
  );
};

export default CatalogColumnData;
