import { gql } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { productUtils } from "@utils";
import { RootState } from "@store";
import { client } from "@/index";
import { useLocale } from "@/context/LocaleProvider";

const GET_PRODUCTS = gql`
  query products($catalogId: CatalogID, $filters: String, $first: Int!) {
    products(catalogId: $catalogId, filters: $filters, first: $first) {
      edges {
        node {
          id
          title
          price
          imgLink
          available
          category
          categoryId
          enrichedData
          link
          data
          deletedAt
        }
      }
    }
  }
`;

/**
 * Custom React hook for managing product information.
 *
 * @returns {{ getProductsInfos: (productIds: Array<number | string>) => Promise<Dic<any>[]>, isGettingProductInfos: boolean }} - An object containing functions and states related to product information.
 */
const useProducts = () => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { locale } = useLocale();
  const [isGettingProductInfos, setIsGettingProductInfos] =
    useState<boolean>(false);

  const catalogId: ID = useSelector(
    (state: RootState) => state.catalog.catalogId
  );

  /**
   * Function to fetch product information based on productIds.
   *
   * @param {Array<number | string>} productIds - An array of product IDs.
   * @returns {Promise<Dic<any>[]>} - A promise resolving to an array of product information.
   */
  const getProductsInfos = async (
    productIds: Array<number | string>
  ): Promise<Dic<any>[]> => {
    if (siteId) {
      setIsGettingProductInfos(true);

      const asyncRes = await client.query({
        query: GET_PRODUCTS,
        variables: {
          catalogId,
          filters: `id IN ${JSON.stringify(productIds)}`,
          first: productIds.length,
        },
        fetchPolicy: "cache-first",
      });

      setIsGettingProductInfos(false);
      return productIds.map((id: string | number) => {
        const info = asyncRes.data.products.edges.find(
          (item: Dic<any>) => item.node.id === id
        )?.node;
        if (!!info) {
          return productUtils.selectProductInfos(info, locale);
        }
      });
    } else return [];
  };

  return {
    getProductsInfos,
    isGettingProductInfos,
  };
};

export default useProducts;
