import { ReportIcon, SettingsIcon } from "@/assets/icons";
import AlgorithmIcon from "@/assets/icons/Algorithm";
import AutomaticAuthenticationIcon from "@/assets/icons/AutomaticAuthentication";
import CampaignsIcon from "@/assets/icons/Campaigns";
import CatalogIcon from "@/assets/icons/Catalog";
import DatasetsIcon from "@/assets/icons/Datasets";
import HomeIcon from "@/assets/icons/Home";
import LastSessionRecoveryIcon from "@/assets/icons/LastSessionRecovery";
import LocationsIcon from "@/assets/icons/Locations";
import MailIcon from "@/assets/icons/Mail";
import MerchandisingIcon from "@/assets/icons/Merchandising";
import RecentlyViewedProductsIcon from "@/assets/icons/RecentlyViewedProducts";
import RecommendationsIcon from "@/assets/icons/Recommendations";
import SuperAdminRoleIcon from "@/assets/icons/SuperAdminRole";
import TargetedPopupsIcon from "@/assets/icons/TargetedPopups";
import TemplatesIcon from "@/assets/icons/Templates";
import VariablesIcon from "@/assets/icons/Variables";
import { SEARCH_TYPE_ENUM } from "@/components/_Organisms/ExperienceList/enums";
import HasEditingRoleWrapper from "@/components/_Templates/HasEditingRoleWrapper";
import SuperAdmin from "@/components/_Templates/SuperAdminWrapper";
import { useHasProperty, useViewport } from "@/hooks";
import { client } from "@/services/graphql/apolloConfig";
import { navigatorUtils } from "@/utils";
import { useFsFlag } from "@flagship.io/react-sdk";
import { Box, DeveloperModeToggle, Stack, Text } from "@includes";
import { CircularProgress, Tooltip, useTheme } from "@mui/material";
import { RootState } from "@store";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import HasCatalogWrapper from "../../../HasCatalogWrapper/HasCatalogWrapper";
import HasReportOrABTestWrapper from "../../../HasReportOrABTestWrapper";
import MobileWrapper from "../../../MobileWrapper";
import { HEIGHT_TOP_BAR } from "../../enums";
import Icon from "../Icon";
import Separator from "../Separator";
import { GET_EXPERIENCES } from "./requests";
import { SideMenuProps } from "./types";
import { FLAGS_ENUM } from "@/utils/enums/flags";

const SideMenu = ({}: SideMenuProps) => {
  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );
  const { hideMerch, hideReport, gettingHasPages, hasBuilder } =
    useHasProperty();
  const location = useLocation();
  const params = useParams<UrlParams>();
  const { siteId, accountId }: any = params;
  const history = useHistory();

  const theme = useTheme();

  const { t }: i18translateType = useTranslation();

  const [experiences, setExperiences] = useState<Array<any>>([]);
  const [totalExperiences, setTotalExperiences] = useState<number>(10);

  const getExperiences = () => {
    return client
      .query({
        query: GET_EXPERIENCES,
        variables: {
          siteId: parseInt(siteId),
          first: totalExperiences,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setExperiences(
          res.data.experiences.edges.map((edge: Dic<any>) => edge.node)
        );
        setTotalExperiences(res.data.experiences.totalCount);
      });
  };

  useEffect(() => {
    if (experiences.length < totalExperiences) {
      getExperiences();
    }
  }, [totalExperiences]);

  const hasTargetPopUp = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.SWITCH)
  );
  const hasLastSessionRecovery = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.PROPULSE)
  );
  const hasRecentlyViewedProducts = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.FLASHBACK)
  );
  const hasAuthentificationAuto = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.CONNECT)
  );

  const flagHasFeatureTemplate = useFsFlag(FLAGS_ENUM.HAS_FEATURE_TEMPLATE);
  const flagValueHasFeatureTemplate = flagHasFeatureTemplate.getValue(false);

  const { isMobile } = useViewport();
  if (gettingHasPages)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <CircularProgress
          sx={{ display: "flex", justifyContent: "center" }}
          size={20}
        />
      </Box>
    );
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.customPalette.others.layer_1,
        overflow: "visible",
        height: isMobile ? 1 : `calc(100% - ${HEIGHT_TOP_BAR})`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: isMobile ? "start" : "center",
          alignItems: "center",
          padding: isMobile ? "16px" : "0px",
          pt: 2,
        }}
      >
        <Stack
          sx={{
            height: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            flex: isMobile ? 1 : "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              WebkitAppearance: "none",
              display: "none",
            },
          }}
          justifyContent={isMobile ? "unset" : "space-between"}
        >
          <Stack
            spacing={isMobile ? "8px" : 0}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems={isMobile ? "baseline" : "center"}
          >
            <MobileWrapper>
              <Box
                sx={{ height: 20, padding: 1, cursor: "pointer" }}
                component="img"
                src={`/${theme.customPalette.mode}/top_logo.svg`}
              />
            </MobileWrapper>

            {/* ---------------------------------------------------------------------------------------------------- */}
            {!hasBuilder && (
              <Icon
                tooltipInfos={{
                  title: t("home"),
                  description: t("home_description"),
                  tag: "",
                  position: "top",
                }}
                Asset={HomeIcon}
                selected={location.pathname.includes("home")}
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/home`,
                  history
                )}
                dataTestId="main-navbar-home"
              />
            )}
            {!hideReport && (
              <HasReportOrABTestWrapper>
                <Icon
                  tooltipInfos={{
                    title: t("report"),
                    description: t("report_description"),
                    tag: "",
                    position: "top",
                  }}
                  Asset={ReportIcon}
                  selected={location.pathname.includes("report")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/report`,
                    history
                  )}
                  dataTestId="main-navbar-report"
                />
              </HasReportOrABTestWrapper>
            )}
            {/* ---------------------------------------------------------------------------------------------------- */}
            {!hasBuilder && <Separator />}
            {!hideMerch && (
              <Icon
                tooltipInfos={{
                  title: t("merchandising"),
                }}
                Asset={MerchandisingIcon}
                selected={location.pathname.includes("merchandising")}
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/merchandising`,
                  history
                )}
                dataTestId="main-navbar-merchandising"
              />
            )}
            {hasBuilder ? (
              <Icon
                tooltipInfos={{
                  title: t("recommendations"),
                }}
                Asset={RecommendationsIcon}
                selected={location.pathname.includes("recommendations/site")}
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/recommendations/site`,
                  history
                )}
                dataTestId="main-navbar-recos"
              />
            ) : (
              <>
                <Icon
                  tooltipInfos={{
                    title: t("recos_web"),
                  }}
                  Asset={RecommendationsIcon}
                  selected={location.pathname.includes("recommendations/site")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/recommendations/site`,
                    history
                  )}
                  dataTestId="main-navbar-recos-web"
                />
                <Icon
                  tooltipInfos={{
                    title: t("recos_mail"),
                  }}
                  Asset={MailIcon}
                  selected={
                    location.pathname.includes("recommendations/emailing") ||
                    location.pathname.includes("emailing")
                  }
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/recommendations/emailing`,
                    history
                  )}
                  dataTestId="main-navbar-recos-mail"
                />
              </>
            )}

            {/* ---------------------------------------------------------------------------------------------------- */}

            {(hasTargetPopUp ||
              hasRecentlyViewedProducts ||
              hasLastSessionRecovery ||
              hasAuthentificationAuto) &&
              !["31", "137", "138"].includes(accountId) &&
              !hasBuilder && (
                <>
                  <Separator />
                  {hasTargetPopUp && (
                    <Icon
                      tooltipInfos={{
                        title: t("targeted_popups"),
                      }}
                      Asset={TargetedPopupsIcon}
                      selected={location.pathname.includes("targeted_popups")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/targeted_popups`,
                        history
                      )}
                      dataTestId="main-navbar-targeted-popups"
                    />
                  )}
                  {hasRecentlyViewedProducts && (
                    <Icon
                      tooltipInfos={{
                        title: t("recently_viewed_products"),
                      }}
                      Asset={RecentlyViewedProductsIcon}
                      selected={location.pathname.includes(
                        "recently_viewed_products"
                      )}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/recently_viewed_products`,
                        history
                      )}
                      dataTestId="main-navbar-viewed-products"
                    />
                  )}
                  {hasLastSessionRecovery && (
                    <Icon
                      tooltipInfos={{
                        title: t("last_session_recovery"),
                      }}
                      Asset={LastSessionRecoveryIcon}
                      selected={location.pathname.includes(
                        "last_session_recovery"
                      )}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/last_session_recovery`,
                        history
                      )}
                      dataTestId="main-navbar-session-recovery"
                    />
                  )}
                  {hasAuthentificationAuto && (
                    <Icon
                      tooltipInfos={{
                        title: t("automatic_authentication"),
                        position: "bottom",
                      }}
                      Asset={AutomaticAuthenticationIcon}
                      selected={location.pathname.includes(
                        "automatic_authentication"
                      )}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/automatic_authentication`,
                        history
                      )}
                      dataTestId="main-navbar-auto-authentication"
                    />
                  )}
                </>
              )}

            {/* ---------------------------------------------------------------------------------------------------- */}
            <Separator />

            <HasCatalogWrapper>
              <Icon
                tooltipInfos={{
                  title: t("catalog"),
                }}
                Asset={CatalogIcon}
                selected={
                  location.pathname.includes("catalog") &&
                  !location.pathname.includes("data/catalog")
                }
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/catalog`,
                  history
                )}
                dataTestId="main-navbar-catalog"
              />
            </HasCatalogWrapper>
            <Icon
              tooltipInfos={{
                title: t("settings"),
              }}
              Asset={SettingsIcon}
              selected={location.pathname.includes("settings")}
              onClick={navigatorUtils.goTo(
                `/${accountId}/${siteId}/settings/account`,
                history
              )}
              dataTestId="main-navbar-settings"
            />

            {/* ---------------------------------------------------------------------------------------------------- */}
            <SuperAdmin>
              <Separator />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  width: "calc(100% - 32px)",
                }}
              >
                <Text
                  noWrap
                  customStyle={{
                    color: theme.customPalette.bluePurple.main,
                    textTransform: "uppercase",
                  }}
                  variant="body2"
                >
                  Dev Mode
                </Text>
                <Tooltip title="Dev mode">
                  <div>
                    <DeveloperModeToggle />
                  </div>
                </Tooltip>
              </Box>
              {devMode && (
                <>
                  {!hasBuilder && (
                    <Icon
                      tooltipInfos={{
                        title: t("algorithms"),
                      }}
                      Asset={AlgorithmIcon}
                      selected={location.pathname.includes("algorithms")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/algorithms`,
                        history
                      )}
                      dataTestId="main-navbar-algorithms"
                    />
                  )}
                  {!hasBuilder && (
                    <Icon
                      tooltipInfos={{
                        title: t("campaigns"),
                      }}
                      Asset={CampaignsIcon}
                      selected={location.pathname.includes("campaigns")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/campaigns`,
                        history
                      )}
                      dataTestId="main-navbar-campaigns"
                    />
                  )}
                  {!hasBuilder && (
                    <Icon
                      tooltipInfos={{
                        title: t("placements"),
                      }}
                      Asset={LocationsIcon}
                      selected={location.pathname.includes("placements")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/placements`,
                        history
                      )}
                      dataTestId="main-navbar-placements"
                    />
                  )}
                  {hasBuilder && (
                    <Icon
                      tooltipInfos={{
                        title: t("datasets"),
                      }}
                      Asset={DatasetsIcon}
                      selected={location.pathname.includes("datasets")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/datasets`,
                        history
                      )}
                      dataTestId="main-navbar-datasets"
                    />
                  )}
                  <HasEditingRoleWrapper>
                    <Icon
                      tooltipInfos={{
                        title: t("variables"),
                      }}
                      Asset={VariablesIcon}
                      selected={location.pathname.includes("variables")}
                      onClick={navigatorUtils.goTo(
                        `/${accountId}/${siteId}/variables`,
                        history
                      )}
                      dataTestId="main-navbar-variables"
                    />
                  </HasEditingRoleWrapper>
                  {hasBuilder && flagValueHasFeatureTemplate && (
                    <HasEditingRoleWrapper>
                      <Icon
                        tooltipInfos={{
                          title: t("templates"),
                        }}
                        Asset={TemplatesIcon}
                        selected={location.pathname.includes("templates")}
                        onClick={navigatorUtils.goTo(
                          `/${accountId}/${siteId}/templates`,
                          history
                        )}
                        dataTestId="main-navbar-templates"
                      />
                    </HasEditingRoleWrapper>
                  )}
                  <Icon
                    tooltipInfos={{
                      title: t("accounts"),
                    }}
                    Asset={SuperAdminRoleIcon}
                    selected={location.pathname.includes(
                      "super_admin/accounts"
                    )}
                    onClick={navigatorUtils.goTo(
                      `/${accountId}/${siteId}/super_admin/accounts`,
                      history
                    )}
                    dataTestId="main-navbar-accounts"
                  />
                </>
              )}
            </SuperAdmin>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SideMenu;
