import { EdgeType } from "@/utils/types/requests";
import { userChangeSite } from "@actions";
import { useQuery } from "@apollo/client";
import { useDebounce, useHasProperty, useViewport } from "@hooks";
import {
  AutoComplete,
  Box,
  Loader,
  Stack,
  StyledTextField,
  Text,
} from "@includes";
import { potionsLocalStorage } from "@localStorage";
import { Divider, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { RootState } from "@store";
import { arrayUtils } from "@utils";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ShortUniqueId from "short-unique-id";
import { CustomPaper } from "./components/CustomPaper";
import { DEFAULT_INPUT_WIDTH, FONT_SIZE } from "./enums";
import { SEARCH_ACCOUNT } from "./requests";
import { FLAGS_ENUM } from "@/utils/enums/flags";

const SiteSelector = () => {
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const theme = useTheme();
  const uid = new ShortUniqueId();
  const { getValueOfFlag } = useHasProperty();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const siteName = useSelector((state: RootState) => state.site.siteName);
  const accountName = useSelector(
    (state: RootState) => state.account.accountName
  );
  const isSuperUser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );

  const [isOpen, setIsOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const [search, setSearch] = useState(inputValue);

  const { data, loading } = useQuery(SEARCH_ACCOUNT, {
    variables: {
      search: selectedValue?.name == search ? "" : search,
      first: 1000,
    },
  });

  const options =
    data?.companies?.edges
      ?.map((edge: EdgeType) => ({
        name: edge?.node?.name,
        id: edge?.node?.id,
        sites: edge?.node?.sites?.edges?.map((subItem: Dic<Dic<string>>) => ({
          id: subItem?.node?.id,
          name: subItem?.node?.name,
          isUnderMaintenance: subItem?.node?.isUnderMaintenance,
        })),
      }))
      ?.map((c: any) => {
        return c?.sites?.map((s: any) => ({
          ...s,
          accountId: c?.id,
          accountName: c?.name,
        }));
      })
      ?.flat() ?? [];

  const sites = useMemo(
    () =>
      arrayUtils.uniqueArray([
        ...options,
        {
          accountId,
          accountName,
          id: siteId,
          name: siteName,
          isUnderMaintenance: false,
        },
      ]),
    [options, isOpen]
  );

  useDebounce(
    () => {
      setSearch(inputValue);
    },
    [inputValue],
    600
  );

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  const handleSelectChange = (value: any) => {
    if (value.toString().length !== 1) {
      setInputWidth(
        20 + value.toString().length * FONT_SIZE < DEFAULT_INPUT_WIDTH
          ? DEFAULT_INPUT_WIDTH
          : 20 + value.toString().length * FONT_SIZE
      );
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  };

  const handleChange = (value: any) => {
    if (value) {
      const { accountId, id, name, accountName } = value;
      if (!!accountId && !!id) {
        potionsLocalStorage.set("accountId", accountId);
        potionsLocalStorage.set("siteId", id);
        potionsLocalStorage.set("accountName", accountName);
        potionsLocalStorage.set("siteName", name);
        dispatch(userChangeSite(siteId, name, accountId, accountName));
        getValueOfFlag(FLAGS_ENUM.HAS_BUILDER, id).then((res: boolean) => {
          if (res) {
            window.location.href = `${window.location.origin}/${accountId}/${id}/recommendations/site
            `;
          } else {
            window.location.href = `${window.location.origin}/${accountId}/${id}/home`;
          }
        });
      }
    }
  };

  useEffect(() => {
    if (siteName) handleSelectChange(siteName);
  }, [siteName]);

  useEffect(() => {
    if (sites?.length > 0 && selectedValue?.length === 0) {
      setSelectedValue(sites?.find((s: any) => s?.id === siteId) ?? []);
      setInputValue(sites?.find((s: any) => s?.id === siteId)?.name ?? "");
    }
  }, [sites]);

  const optionsSortedNySiteName = useMemo(
    () =>
      options
        ?.filter((site: Dic<any>) => !isEmpty(site?.name))
        .sort(arrayUtils.dynamicSort("name")),
    [options]
  );

  if (loading && !sites) return <Loader />;

  return (
    <Box
      sx={{
        "& fieldset": { borderRadius: 1 },
      }}
    >
      <AutoComplete
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        filterOptions={(x) => x}
        autoHighlight
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && e.target.value) handleChange(e.target.value);
        }}
        PaperComponent={
          CustomPaper as unknown as React.JSXElementConstructor<
            React.HTMLAttributes<HTMLElement>
          >
        }
        onChange={(e: React.SyntheticEvent, value: any) => {
          handleChange(value);
        }}
        groupBy={(option: any) => option?.accountId}
        value={selectedValue}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        noOptionsText=""
        loadingText=""
        loading={loading}
        disableClearable
        id="site-selector"
        options={optionsSortedNySiteName}
        getOptionLabel={(option: any) => option?.name ?? ""}
        sx={{
          width: inputWidth,
          maxWidth: isMobile ? 120 : 200,
        }}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label=""
            sx={{
              fontSize: FONT_SIZE,
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                py: 0,
                "& input": {
                  py: 0.75,
                },
              },
            }}
            value={inputValue}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props: any, option: any, { selected }: any) => {
          return (
            <li
              key={uid()}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              style={{
                cursor: "pointer",
                "&:hover": {
                  background: theme.customPalette.others.layer_2,
                },
              }}
            >
              <Text
                variant="body1"
                customStyle={{
                  paddingLeft: 1,
                }}
              >
                {option.name}
              </Text>
            </li>
          );
        }}
        renderGroup={(params) => {
          const account = sites?.find(
            (c: any) => c?.accountId === params?.group
          );
          return (
            <Box key={uid()} p={1}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  padding: "2px 0",
                }}
              >
                <Box
                  sx={{ width: 16, height: 16 }}
                  component="img"
                  alt=""
                  src={`/${theme.palette.mode}/others/account.svg`}
                />
                <Text variant="body2">{account?.accountName}</Text>
              </Stack>
              <Box>{params.children}</Box>
              <Divider />
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default SiteSelector;
