import Close from "@/assets/icons/Close";
import { useViewport } from "@/hooks";
import { client } from "@/services/graphql/apolloConfig";
import { sendNotification } from "@/services/redux/actions";
import { Button, Dialog, Stack, Text } from "@includes";
import { Box, IconButton, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useSelector as useXStateSelector } from "@xstate/react";
import { isEmpty } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CategoriesSelector from "./components/CategoriesSelector";
import { useMerchandisingDeployment } from "./hooks/useMerchandisingDeployment";
import { UPDATE_EXPERIENCE } from "./requests";
import { IDeploymentDialog } from "./types";

const DeploymentDialog: React.FC<IDeploymentDialog> = ({
  open,
  handleClose,
  merchSelected,
}) => {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const merchVariable = merchSelected?.collection?.settings?.merch_variable;
  const deploymentService = useMerchandisingDeployment();
  const contextSelector = (state: any) => state.context;
  const context = useXStateSelector(
    deploymentService.deploymentService,
    contextSelector
  );
  const { send } = deploymentService.deploymentService;

  React.useEffect(() => {
    if (isEmpty(context)) {
      send({
        type: "SET_MERCHANDISING",
        data: {
          includedCategories: [],
          excludedCategories: [],
          ...merchSelected,
        },
      });
    }
  }, [merchSelected]);

  const handleUpdateMerch = () => {
    client
      .mutate({
        mutation: UPDATE_EXPERIENCE,
        variables: {
          experienceId: merchSelected?.id,
          experience: context,
        },
      })
      .then(() => {
        dispatch(
          sendNotification(
            "deployment_send",
            t("deployment"),
            t("deployment_send"),
            "success"
          )
        );
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          sendNotification(
            "deployment_send",
            t("deployment"),
            `${t("error")}: ${err.message}`,
            "error"
          )
        );
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "min(100vw, 550px)",
            maxWidth: "min(100vw, 550px)",
          },
        }}
      >
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack spacing={1}>
              <Text variant="h1">
                {t("deployment")} {merchSelected?.name}
              </Text>
            </Stack>
            <IconButton onClick={handleClose}>
              <Close color={theme.customPalette.grey.default} />
            </IconButton>
          </Stack>
          <DialogContent sx={{ padding: 0 }}>
            {merchVariable === "category_id" && (
              <Box>
                <Text customStyle={{ mb: 2 }}>
                  {t("select_which_you_want_merch_apply", {
                    variable: merchVariable ?? "",
                  })}
                </Text>
                <CategoriesSelector />
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ gap: 3, p: 0 }}>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
              spacing={1}
            >
              <Button
                btnVariant="outline"
                id="cancelBtn"
                onClick={handleClose}
                customStyle={{ width: isMobile ? "100%" : "50%" }}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={handleUpdateMerch}
                customStyle={{ width: isMobile ? "100%" : "50%" }}
              >
                {t("deploy_btn")}
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      </Dialog>
    </div>
  );
};

export default DeploymentDialog;
