export enum CURRENCY_CODE {
  "EUR" = "€" as any,
  "JPY" = "¥" as any,
  "USD" = "$" as any,
  "PLN" = "zł" as any,
  "GBP" = "£" as any,
}

export const getCurrencyKey = (symbol: string): string | undefined => {
  return Object.entries(CURRENCY_CODE).find(
    ([key, value]) => value === symbol
  )?.[0];
};
