export enum FLAGS_ENUM {
  HAS_BUILDER = "has_builder",
  HAS_FEATURE_TEMPLATE = "has_feature_template",
  HIDE_MERCH = "hide_merch",
  HIDE_REPORT = "hide_report",
  HIDE_CATALOG = "hide_catalog",
  HAS_SKU_REPORT = "has_sku_report",
  HAS_EMOTION_AI = "has_emotion_ai",
  HAS_ABTEST = "has_abtest",
}
