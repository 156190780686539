import { formatUtils } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { getTooltipForColumn } from "./helpers";
import { CALCULATE_BY_ALL } from "../KeyIndicators/enums";
import { SUMMARY_HEADERS } from "@/utils/enums/graphql";
import { getTraduction } from "@/utils/helpers/traductions";

function getColumns(
  t: (text: string) => string,
  locale: string = "fr",
  currency: string = "€"
): any {
  return [
    {
      field: "segment",
      headerName: "",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) =>
        getTraduction(t, params.row.segment),
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return "";
        }
        return (
          <Typography variant="body1">
            {getTraduction(t, params.row.segment)}
          </Typography>
        );
      },
    },
    {
      field: "visitors",
      headerName: t("visitors"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.visitors);
        }
        return formatUtils.formatInt(params.row.visitors, locale);
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.bluePurple.dark }}
              >
                ({formatUtils.formatToPercent(params.row.visitors)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.VISITORS),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatInt(params.row.visitors, locale)}
          </Typography>
        );
      },
    },
    {
      field: "revenues",
      headerName: t("revenues"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.revenues);
        }
        return formatUtils.formatIntegerCurrency(
          params.row.revenues,
          locale,
          currency
        );
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.bluePurple.light }}
              >
                ({formatUtils.formatToPercent(params.row.revenues)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.REVENUES),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatIntegerCurrency(
              params.row.revenues,
              locale,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "revenue_per_user",
      headerName: t("revenue_per_user"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatMultiply(params.row.revenue_per_user, 2);
        }
        return formatUtils.formatCurrency(
          params.row.revenue_per_user,
          locale,
          currency
        );
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.citrus.main }}
              >
                ({formatUtils.formatMultiply(params.row.revenue_per_user, 2)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.REVENUE_PER_USER),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatCurrency(
              params.row.revenue_per_user,
              locale,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "transactions",
      headerName: t("transactions"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.transactions);
        }
        return formatUtils.formatInt(params.row.transactions, locale);
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.grey.light }}
              >
                ({formatUtils.formatToPercent(params.row.transactions)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.TRANSACTIONS),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatInt(params.row.transactions, locale)}
          </Typography>
        );
      },
    },

    {
      field: "transaction_rate",
      headerName: t("transaction_rate"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatMultiply(params.row.transaction_rate, 2);
        }
        return formatUtils.formatToPercent(params.row.transaction_rate);
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.grey.light }}
              >
                ({formatUtils.formatMultiply(params.row.transaction_rate, 2)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.TRANSACTION_RATE),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatToPercent(params.row.transaction_rate)}
          </Typography>
        );
      },
    },
    {
      field: "average_cart",
      headerName: t("average_cart"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.average_cart);
        }
        return formatUtils.formatCurrency(
          params.row.average_cart,
          locale,
          currency
        );
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.grey.light }}
              >
                ({formatUtils.formatToPercent(params.row.average_cart)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.AVERAGE_CART),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatCurrency(
              params.row.average_cart,
              locale,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "sales_index",
      headerName: t("sales_index"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.sales_index);
        }
        formatUtils.formatInt(params.row.sales_index, locale);
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.grey.light }}
              >
                ({formatUtils.formatToPercent(params.row.sales_index)})
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(SUMMARY_HEADERS.SALES_INDEX),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatInt(params.row.sales_index, locale)}
          </Typography>
        );
      },
    },
    {
      field: "sales_index_sku_unique",
      headerName: t("sales_index_sku_unique"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return formatUtils.formatToPercent(params.row.sales_index_sku_unique);
        }
        formatUtils.formatInt(params.row.sales_index_sku_unique, locale);
      },
      renderCell: (params: GridRowParams) => {
        if (params.row.segment === "ratio") {
          return (
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customPalette.grey.light }}
              >
                (
                {formatUtils.formatToPercent(params.row.sales_index_sku_unique)}
                )
              </Typography>
              {getTooltipForColumn(
                CALCULATE_BY_ALL.includes(
                  SUMMARY_HEADERS.SALES_INDEX_SKU_UNIQUE
                ),
                t
              )}
            </Stack>
          );
        }
        return (
          <Typography variant="body1">
            {formatUtils.formatInt(params.row.sales_index_sku_unique, locale)}
          </Typography>
        );
      },
    },
  ];
}

export default getColumns;
