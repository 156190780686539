import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Error, Layer1Box, Stack } from "@includes";
import { Skeleton, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AB_TEST_EVOLUTION_METRICS_ENUM } from "../../enums";
import { GraphDataType } from "../../types";
import EvolutionGraph from "../EvolutionGraph";
import EvolutionHeader from "../EvolutionHeader";
import { formatDataForGraph } from "./helpers";
import { GET_AB_TEST_EVOLUTION_DATA } from "./requests";
import { IComponent, ResponseAbTestEvolutionDataType } from "./types";
import { useLocale } from "@/context/LocaleProvider";

const Evolution: React.FC<IComponent> = ({ filters }) => {
  const { t }: i18translateType = useTranslation();
  const [evolutionMetric, setEvolutionMetric] =
    useState<AB_TEST_EVOLUTION_METRICS_ENUM>(
      AB_TEST_EVOLUTION_METRICS_ENUM.REVENUES_PER_VISITOR
    );
  const theme = useTheme();
  const { startDate, endDate, devices, abExperimentId } = filters;
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { locale } = useLocale();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<GraphDataType[]>([]);
  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_AB_TEST_EVOLUTION_DATA,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          segmentA: `potions;ab_experiment_${abExperimentId};A;all`,
          segmentB: `potions;ab_experiment_${abExperimentId};B;all`,
          devices,
          query: evolutionMetric,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseAbTestEvolutionDataType) => {
        setData(formatDataForGraph(res, evolutionMetric, locale, currency));
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(evolutionMetric) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices) &&
      !isEmpty(abExperimentId)
    )
      getData();
  }, [evolutionMetric, startDate, endDate, devices, abExperimentId]);

  return (
    <Layer1Box
      sx={{
        maxWidth: "100%",
        mt: 3,
      }}
    >
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <EvolutionHeader
            filters={filters}
            evolutionMetric={evolutionMetric}
            setEvolutionMetric={setEvolutionMetric}
          />

          {status !== StatusEnum.NORMAL || isEmpty(data) ? (
            status === StatusEnum.ERROR ? (
              <Error />
            ) : (
              <Skeleton
                sx={{ bgcolor: theme.customPalette.others.layer_2 }}
                height={400}
                variant="rounded"
                animation="wave"
              />
            )
          ) : (
            <EvolutionGraph
              evolutionMetric={evolutionMetric}
              data={data}
              locale={locale}
              currency={currency}
            />
          )}
        </Box>
      </Stack>
    </Layer1Box>
  );
};

export default Evolution;
