import { useSelector as useXStateSelector } from "@xstate/react";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { DraggableProductList } from "@/includes";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import { useRunCollection, useSetState, useThrottle } from "@/hooks";
import { Interpret } from "../../Interpret";
import { IRunAndManipulate } from "./types";
import { VISIBLE_FIELDS } from "./enums";

const contextSelector = (state: any) => state.context;

// /!\ EMERCH ONLY
// todo refonte
const RunAndManipulate: React.FC<IRunAndManipulate> = ({ slotProps }) => {
  const interpreter = new Interpret();
  const { selectedCategory } = slotProps;

  const { t }: i18translateType = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const configureAlgorithmServices = useConfigureAlgorithm();

  const [params, setParams] = useSetState({});

  const { send } = configureAlgorithmServices.algorithmService;

  const context = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    contextSelector
  );

  const { pins, blocks } = context;

  const categoryPins: Dic<string> = pins?.[selectedCategory.id] ?? {};

  const { settings }: Dic<any> = useMemo(
    () => interpreter.transformObjectIntoConditionalRule(context),
    [context]
  );

  const requiredParameters = settings.required_parameters ?? {};

  const { newHandleRun, runningCollection, collectionResult } =
    useRunCollection(siteId);

  const throtteledParams = useThrottle(params);
  const throtteledSettings = useThrottle(settings);

  useEffect(() => {
    if (throtteledSettings?.blocks?.length > 0) {
      newHandleRun(throtteledSettings, requiredParameters, throtteledParams);
    }
  }, [throtteledSettings, throtteledParams]);

  useEffect(() => {
    if (!!selectedCategory.id)
      setParams({ ...params, category_id: selectedCategory?.id });
  }, [selectedCategory]);

  const handleChangePins = (newCategoryPins: Dic<ID>) => {
    const newPins = { ...pins };
    newPins[selectedCategory.id] = { ...newCategoryPins };
    send({ type: "SET_PINS", data: { pins: newPins } });
  };

  const unpin = (productId: ID) => {
    handleChangePins(
      Object.fromEntries(
        Object.entries(categoryPins).filter(
          (entry: Array<any>) => entry[1] !== productId
        )
      )
    );
  };

  const pushProductTo = (
    productId: ID,
    position: number,
    newPinsForCategory: Dic<ID> | null = null
  ): any => {
    const newCategoryPins = newPinsForCategory ?? categoryPins;

    const pushedProducts = Object.fromEntries(
      Object.entries(newCategoryPins ? { ...newCategoryPins } : {}).filter(
        (pin: Array<any>) => pin[1] !== productId
      )
    );

    if (pushedProducts[position] && pushedProducts[position] !== productId) {
      const currentlyPushed = pushedProducts[position];
      pushedProducts[position] = productId;
      return pushProductTo(currentlyPushed, position + 1, pushedProducts);
    } else {
      pushedProducts[position] = productId;
    }
    handleChangePins(pushedProducts);
  };

  const products = useMemo(
    () =>
      collectionResult.map((runResult: Array<Dic<any>>, index: number) => ({
        ...runResult,
        isPinned: !!categoryPins[index + 1],
      })),
    [collectionResult]
  );

  return (
    <DraggableProductList
      list="run result"
      products={products}
      isLoading={runningCollection}
      pinnable
      pin={pushProductTo}
      unpin={unpin}
      pushProduct={pushProductTo}
      categoryId={selectedCategory?.id ?? ""}
    />
  );
};

export default RunAndManipulate;
