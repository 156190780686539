import { Layer1Box, Stack, Text } from "@/includes";
import { Tooltip, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { OverviewVisualizationProps } from "./types";
import { useTranslation } from "react-i18next";
import { formatUtils, stringUtils } from "@/utils";
import { getNbDays } from "./helpers";

const OverviewVisualization = ({
  data,
  audience,
  filters,
  locale = "fr",
  currency = "€",
}: OverviewVisualizationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const diff = data.revenuePerUserA - data.revenuePerUserB;
  const winner = diff > 0 ? "A" : "B";
  return (
    <Layer1Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
        position: "relative",
      }}
    >
      <Stack spacing={3} alignItems="start" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack>
            <Text variant="h2">{`${t("the_group")} ${winner}`}</Text>
            <Text variant="body1">{`${audience[winner]} ${t(
              "sur"
            )} ${stringUtils.capitalizeFirstLetter(filters.devices[0])} ${t(
              "potential_gain"
            )} ${t("after")} ${getNbDays(
              filters.startDate,
              filters.endDate
            )} ${t("days_test")} ${formatUtils.formatInt(
              data.totalVisitors,
              locale
            )} ${t("visitors")} ${t("of")} :`}</Text>
          </Stack>

          <Tooltip title={t("ab_test_recap_tooltip")}>
            <HelpOutlineIcon
              sx={{
                width: 20,
                height: 20,
                color: theme.customPalette.grey.light,
              }}
            />
          </Tooltip>
        </Stack>
        <Text variant="h1">
          {formatUtils.formatIntegerCurrency(
            Math.abs(diff) * data.totalVisitors,
            locale,
            currency
          )}
        </Text>
      </Stack>
    </Layer1Box>
  );
};

export default OverviewVisualization;
