import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Error, Stack, Text } from "@includes";
import { Skeleton } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OverviewVisualization from "../OverviewVisualization";
import { formatDataToAbTestOverview } from "./helpers";
import { GET_AB_TEST_OVERVIEW } from "./requests";
import {
  AbTestOverviewDataType,
  IOverview,
  ResponseAbTestOverviewDataType,
} from "./types";
import { useLocale } from "@/context/LocaleProvider";

const Overview: React.FC<IOverview> = ({ filters, audience, abTest }) => {
  const { t }: i18translateType = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { locale } = useLocale();
  const [loading, setLoading] = useState<StatusType>(StatusEnum.LOADING);
  const [data, setData] = useState<AbTestOverviewDataType>();
  const { startDate, endDate, devices, abExperimentId } = filters;

  const getData = () => {
    setLoading(StatusEnum.LOADING);
    client
      .query({
        query: GET_AB_TEST_OVERVIEW,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          segmentA: `potions;ab_experiment_${abExperimentId};A;all`,
          segmentB: `potions;ab_experiment_${abExperimentId};B;all`,
          devices: devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseAbTestOverviewDataType) => {
        setData(formatDataToAbTestOverview(res));
        setLoading(StatusEnum.NORMAL);
      })
      .catch(() => setLoading(StatusEnum.ERROR));
  };
  useEffect(() => {
    if (
      !isEmpty(abExperimentId) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices)
    ) {
      getData();
    }
  }, [startDate, endDate, devices, abExperimentId]);

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Text variant="h3">{t("overview")}</Text>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {loading === StatusEnum.ERROR ? (
          <Error />
        ) : isEmpty(data) || loading === StatusEnum.LOADING ? (
          <Skeleton
            sx={{ bgcolor: (theme) => theme.customPalette.others.layer_2 }}
            height={110}
            variant="rounded"
            animation="wave"
          />
        ) : (
          <OverviewVisualization
            data={data}
            audience={audience}
            filters={filters}
            locale={locale}
            currency={currency}
          />
        )}
      </Box>
    </Stack>
  );
};

export default Overview;
