import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  LOGGED_IN,
  LOGOUT,
  CHANGE_SITE,
  CREATE_ACCOUNT,
} from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  accountId: potionsLocalStorage.accountId,
  accountName: potionsLocalStorage.accountName ?? "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case CHANGE_SITE:
        draft.accountId = action.payload.accountId;
        draft.accountName = action.payload.accountName;
        break;
      case LOGGED_IN:
        draft.accountId = action.payload.accountId;
        draft.accountName = action.payload.accountName;
        break;
      case LOGOUT:
        draft.accountId = null;
        draft.accountName = "";
        break;
      case CREATE_ACCOUNT:
        draft.accountId = action.payload.accountId;
        draft.accountName = action.payload.accountName;
        break;

      default:
        break;
    }
  });
}
