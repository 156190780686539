import { IIcon } from "./types";

const SendIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22449_274)">
        <path
          d="M15.6518 23.4071C15.4118 23.4071 15.1718 23.3771 14.9318 23.3071C14.5018 23.1871 14.1018 22.9471 13.7818 22.6271L10.7618 19.6271L7.59179 21.2671C7.32179 21.4071 7.00179 21.3971 6.74179 21.2271C6.48179 21.0671 6.33179 20.7871 6.34179 20.4771L6.47179 15.3271L2.39179 11.2271C2.09179 10.9271 1.86179 10.5471 1.74179 10.1371C1.62179 9.72714 1.60179 9.28714 1.68179 8.86714C1.76179 8.42714 1.97179 7.99714 2.27179 7.63714C2.58179 7.28714 2.97179 7.01714 3.42179 6.86714L20.4918 1.15714C20.9418 0.987136 21.4518 0.957136 21.9418 1.05714C22.4318 1.16714 22.8818 1.40714 23.2318 1.75714C23.2318 1.75714 23.2418 1.76714 23.2518 1.77714C23.6018 2.13714 23.8518 2.57714 23.9518 3.06714C24.0618 3.56714 24.0218 4.07714 23.8418 4.54714L18.1618 21.5871C18.0218 22.0171 17.7718 22.3971 17.4318 22.7071C17.1018 23.0171 16.6918 23.2271 16.2518 23.3271C16.0518 23.3771 15.8518 23.3971 15.6418 23.3971L15.6518 23.4071ZM10.9318 17.7171C11.1518 17.7171 11.3718 17.8071 11.5318 17.9671L15.0018 21.4171C15.1218 21.5371 15.2518 21.6171 15.4018 21.6571C15.5518 21.6971 15.7118 21.7071 15.8618 21.6571C16.0318 21.6171 16.1718 21.5471 16.2818 21.4371C16.4018 21.3271 16.4918 21.1971 16.5418 21.0471L22.2418 3.98714C22.2718 3.91714 22.2918 3.83714 22.3018 3.76714L8.20179 15.3971L8.10179 19.0771L10.5418 17.8171C10.6618 17.7571 10.8018 17.7171 10.9318 17.7171ZM20.6618 2.90714L3.96179 8.47714C3.81179 8.52714 3.67179 8.62714 3.56179 8.74714C3.45179 8.86714 3.38179 9.02713 3.35179 9.18713C3.32179 9.34713 3.32179 9.50713 3.37179 9.64713C3.41179 9.78714 3.49179 9.91714 3.59179 10.0271L7.40179 13.8371L20.6618 2.90714Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SendIcon.defaultProps = {
  color: "transparent",
};

export default SendIcon;
