import { CURRENCY_CODE, getCurrencyKey } from "@/hooks/useGetCurrency/enums";
import { EndIDFieldType } from "../entityFields/enums";
import { getDefaultValueFromType } from "../entityFields/helpers";
import { FieldType } from "../entityFields/types";

/**
 * Utility class for formatting various types of data.
 */
export class FormatUtils {
  /**
   * Format a price as a string with euro symbol.
   *
   * @param price - The price to format.
   * @param locale - The locale (default is "fr").
   * @param currency - Symbol of currency (default "€")
   * @returns The formatted price string.
   */
  formatPrice = (
    price: string | undefined,
    locale: string = "fr",
    currency: string = "€"
  ): string => {
    if (!price) return "-";
    return parseFloat(price).toLocaleString(locale, {
      style: "currency",
      currency: getCurrencyKey(currency),
      maximumFractionDigits: 2,
    });
  };

  /**
   * Format a decimal number as a percentage string.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string.
   */
  formatToPercent = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "0 %";
    return `${(x * 100)?.toFixed(accuracy)?.toString()?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a percentage string with sign.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string.
   */
  formatToPercentWithSign = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "0 %";
    const sign = x >= 0 ? "+" : "-";
    return `${sign} ${Math.abs(x * 100)
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a percentage string with a sign + or -.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string with a sign.
   */
  formatToPercentWithSymbol = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "+ 0 %";
    const sign = x >= 0 ? "+" : "-";
    return `${sign} ${(Math.abs(x) * 100)
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a multiplied string.
   *
   * @param x - The decimal number to format as multiplied.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @param signPosition - The position of the sign ("before" or "after").
   * @returns The formatted multiplied string.
   */
  formatMultiply = (x = 0.0, accuracy = 1, signPosition = "before"): string => {
    if (!x || typeof x === "string") return "0";
    return ` ${signPosition === "before" ? "x " : ""}${x
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")}${signPosition === "after" ? "x" : ""}`;
  };

  /**
   * Format a number as a currency string.
   *
   * @param x - The number to format as currency.
   * @param locale - The locale (default is "fr").
   * @param currency - The currency symbol (default is "€").
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @returns The formatted currency string.
   */
  formatCurrency = (
    x: number | string = 0.0,
    locale = "fr",
    currency = "€",
    accuracy = 2
  ): string => {
    const value = typeof x === "number" ? x : parseFloat(x);
    return value.toLocaleString(locale, {
      style: "currency",
      currency: getCurrencyKey(currency),
      maximumFractionDigits: accuracy,
    });
  };

  /**
   * Format a number as a currency string.
   *
   * @param x - The number to format as currency.
   * @param locale - The locale (default is "fr").
   * @param currency - The currency symbol (default is "€").
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @returns The formatted currency string.
   */
  formatCurrencyWithSign = (
    x: number | string = 0.0,
    locale = "fr",
    currency = "€",
    accuracy = 2
  ): string => {
    const value = typeof x === "number" ? x : parseFloat(x);
    const sign = value >= 0 ? "+" : "-";
    const val = this.formatCurrency(
      Math.abs(value),
      locale,
      currency,
      accuracy
    );

    return `${sign} ${val}`;
  };

  /**
   * Format an integer as a string with thousand separators.
   *
   * @param value - The integer value to format.
   * @param locale - The locale (default is "fr").
   * @returns The formatted integer string.
   */
  formatInt = (value: number = 0, locale: string = "fr"): string => {
    return value.toLocaleString(locale);
  };

  /**
   * Format an integer as a string with thousand separators with sign.
   *
   * @param value - The integer value to format.
   * @param locale - The locale (default is "fr").
   * @returns The formatted integer string.
   */
  formatIntWithSign = (value: number = 0, locale: string = "fr"): string => {
    const sign = value >= 0 ? "+" : "-";
    return `${sign} ${this.formatInt(Math.abs(value), locale)}`;
  };

  /**
   * Format an integer as a currency string.
   *
   * @param value - The integer value to format.
   * @param locale - The locale (default is "fr").
   * @param currency - The currency symbol (default is "€").
   * @returns The formatted currency string.
   */
  formatIntegerCurrency = (
    value: number = 0.0,
    locale = "fr",
    currency = "€"
  ): string => {
    return value.toLocaleString(locale, {
      style: "currency",
      currency: getCurrencyKey(currency),
      maximumFractionDigits: 0,
    });
  };

  /**
   * Format a float as a string with a specified number of decimal places.
   *
   * @param value - The float value to format.
   * @param locale - The locale (default is "fr").
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @returns The formatted float string.
   */
  formatFloat = (
    value: number = 0.0,
    locale: string = "fr",
    accuracy = 2
  ): string => {
    return value.toLocaleString(locale, {
      maximumFractionDigits: accuracy,
    });
  };

  /**
   * Format a result of graphql introspection as an array for input fields.
   *
   * @param result - The result of graphql introspection.
   * @returns The formatted array.
   */
  formatGraphQLInputFieldsIntrospection = (data: any): Array<FieldType> =>
    data?.__type?.inputFields?.map((field: any) => {
      return {
        name: field.name,
        type: field.type?.name ?? field.type?.ofType?.name,
      };
    }) ?? [];

  /**
   * Format a result of graphql introspection as an array for fields.
   *
   * @param result - The result of graphql introspection.
   * @returns The formatted array.
   */
  formatGraphQLFieldsIntrospection = (data: any): Array<FieldType> =>
    data?.__type?.fields?.map((field: any) => {
      return {
        name: field.name,
        type: field.type?.name ?? field.type?.ofType?.name,
      };
    }) ?? [];

  /**
   * Format a object of array of fields.
   *
   * @param fields - Array of fields.
   * @returns The object.
   */
  formatFieldsArray = (fields: Array<FieldType>): any => {
    const newObject: any = {};
    fields.forEach((field: FieldType) => {
      newObject[field.name] = field.type.endsWith(EndIDFieldType)
        ? null
        : getDefaultValueFromType(field.type);
    });
    return newObject;
  };

  /**
   * Format a string in kebab case.
   *
   * @param text - The string to format.
   * @returns The string in kebab case.
   */
  formatStringInKebabCase = (text: string): string => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1_$2")
      .replace(/[\s_]+/g, "_")
      .toLowerCase();
  };
}
