import {
  InviteIcon,
  PerformancesIcon,
  RulesIcon,
  SortIcon,
  ToolsIcon,
} from "@/assets/icons";
import { bgcolor } from "@mui/system";

export const allExceptionsChecked = (
  exceptions: null | Array<string>,
  booleans: Dic<boolean>
) => {
  const { hideCatalog, hideReport, hasMember } = booleans;
  if (!exceptions) return true;
  else {
    let res = true;
    exceptions.forEach((exception: string) => {
      if (
        (exception === "rules" && hideCatalog) ||
        (exception === "analytics" && hideReport) ||
        (exception === "no_demo" && !hasMember)
      )
        res = false;
    });
    return res;
  }
};

export const getTips = (t: (text: string) => string, theme: Dic<any>) => [
  {
    title: t("sort_category_pages_tips_title"),
    description: t("sort_category_pages_tips_description"),
    variant: "blue",
    backgroundColor: theme.customPalette.bluePurple.slighty,
    borderColor: theme.customPalette.bluePurple.dark,
    icon: <SortIcon color={theme.customPalette.bluePurple.dark} />,
    exceptions: null,
    redirectTo: "merchandising",
  },
  {
    title: t("connect_your_tools_tips_title"),
    description: t("connect_your_tools_tips_description"),
    backgroundColor: theme.customPalette.bluePurple.white,
    borderColor: theme.customPalette.bluePurple.light,
    icon: <ToolsIcon color={theme.customPalette.bluePurple.light} />,
    exceptions: null,
    redirectTo: "settings/integrations",
  },
  {
    title: t("invite_new_users_tips_title"),
    description: t("invite_new_users_tips_description"),
    backgroundColor: theme.customPalette.citrus.lighter,
    borderColor: theme.customPalette.citrus.main,
    icon: <InviteIcon color={theme.customPalette.citrus.main} />,
    exceptions: ["no_demo"],
    redirectTo: "settings/users",
  },
  {
    title: t("play_around_with_your_rules_tips_title"),
    description: t("play_around_with_your_rules_tips_description"),
    backgroundColor: theme.customPalette.success.complementary,
    borderColor: theme.customPalette.success.main,
    icon: <RulesIcon color={theme.customPalette.success.main} />,
    exceptions: ["rules"],
    redirectTo: "recommendations/site",
  },
  {
    title: t("compare_your_performances_tips_title"),
    description: t("compare_your_performances_tips_description"),
    backgroundColor: theme.customPalette.info.complementary,
    borderColor: theme.customPalette.info.main,
    icon: <PerformancesIcon color={theme.customPalette.info.main} />,
    exceptions: ["analytics"],
    redirectTo: "analytics",
  },
  {
    title: t("add_experiences_into_emails_tips_title"),
    description: t("add_experiences_into_emails_tips_description"),
    backgroundColor: theme.customPalette.warning.complementary,
    borderColor: theme.customPalette.warning.main,
    icon: <PerformancesIcon color={theme.customPalette.warning.main} />,
    exceptions: null,
    redirectTo: "recommendations/emailing/create",
  },
];
