import ProductCard from "@/components/_Templates/NewSlider/components/ProductCard";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { SliderProps } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { useLocale } from "@/context/LocaleProvider";

const NewSlider = ({ products, productsPerRow = 4 }: SliderProps) => {
  const theme = useTheme();
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { locale } = useLocale();
  const [startIndex, setStartIndex] = useState(0);

  const productWidth = 100 / productsPerRow;

  const handleNext = () => {
    if (startIndex < products.length - productsPerRow) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Button
        onClick={handlePrev}
        disabled={startIndex === 0}
        variant="contained"
        sx={{
          minWidth: "40px",
          height: "40px",
          mr: 2,
          backgroundColor: theme.customPalette.bluePurple.main,
          "&:hover": {
            backgroundColor: theme.customPalette.bluePurple.dark,
          },
        }}
      >
        <ArrowLeftIcon color={theme.customPalette.white.main} />
      </Button>

      <Box
        sx={{
          overflow: "hidden",
          flex: 1,
          position: "relative",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            transition: "transform 0.5s ease",
            transform: `translateX(-${startIndex * productWidth}%)`,
            width: `${productsPerRow * productWidth}%`,
          }}
        >
          {products.map((product, index) => (
            <ProductCard
              key={product?.id}
              product={product}
              productPerRow={productsPerRow}
              locale={locale}
              currency={currency}
            />
          ))}
        </Stack>
      </Box>

      <Button
        onClick={handleNext}
        disabled={startIndex >= products.length - productsPerRow}
        variant="contained"
        sx={{
          minWidth: "40px",
          height: "40px",
          ml: 2,
          backgroundColor: theme.customPalette.bluePurple.main,
          "&:hover": {
            backgroundColor: theme.customPalette.bluePurple.dark,
          },
        }}
      >
        <ArrowRightIcon color={theme.customPalette.white.main} />
      </Button>
    </Box>
  );
};

export default NewSlider;
