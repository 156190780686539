/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInterpret } from "@xstate/react";
import { createContext, useContext } from "react";
import { ActorRefFrom, assign, createMachine } from "xstate";
import { Event } from "./types";

const DeploymentMachine = createMachine<any, Event>({
  id: "DeploymentMachine",
  context: {},
  on: {
    SET_MERCHANDISING: {
      actions: assign((context, event) => {
        return {
          ...context,
          ...event.data,
        };
      }),
    },
    SET_INCLUDED_CATEGORIES: {
      actions: assign((context, event) => ({
        ...context,
        includedCategories: [...(event.data.includedCategories ?? [])],
      })),
    },
    SET_EXCLUDED_CATEGORIES: {
      actions: assign((context, event) => ({
        ...context,
        excludedCategories: [...(event.data.excludedCategories ?? [])],
      })),
    },
  },
  states: {},
});

interface DeploymentContextType {
  deploymentService: ActorRefFrom<typeof DeploymentMachine>;
}

export const DeploymentContext = createContext({} as DeploymentContextType);

export default function DeploymentProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const deploymentService = useInterpret(DeploymentMachine);

  return (
    <DeploymentContext.Provider value={{ deploymentService }}>
      {children}
    </DeploymentContext.Provider>
  );
}

export function useMerchandisingDeployment() {
  return useContext(DeploymentContext);
}
