import { useViewport } from "@/hooks";
import { Text } from "@/includes";
import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "./types";
import {
  HOME_OVERVIEW_LEGEND_ENUMS,
  HOME_OVERVIEW_QUERIES_ENUMS,
} from "../../enums";
import { formatUtils } from "@/utils";
import { useLocale } from "@/context/LocaleProvider";

const EvolutionTooltip = ({ active, payload }: TooltipProps) => {
  const theme = useTheme();
  const isMobile = useViewport();
  const { t } = useTranslation();
  if (active && payload) {
    return (
      <Stack
        key={JSON.stringify(payload[0]?.payload?.evolutionMetricLabel)}
        sx={{
          padding: 1,
          background: theme.customPalette.others.layer_1,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          borderRadius: 2,
          minWidth: isMobile ? "unset" : 300,
        }}
        justifyContent="start"
        alignItems="start"
        spacing={1}
      >
        <Text variant="body2">{payload[0]?.payload?.evolutionMetricLabel}</Text>
        <Stack
          sx={{
            borderLeft: `2px solid ${theme.customPalette.bluePurple.light}`,
            pl: 1,
          }}
        >
          <Text textType="secondary" variant="body3">
            {`${payload[0]?.payload?.date}`}
          </Text>
          <Text
            variant="body2"
            customStyle={{ color: theme.customPalette.bluePurple.light }}
          >
            {payload[0]?.payload?.evolutionMetricLabel ===
            t(HOME_OVERVIEW_LEGEND_ENUMS[HOME_OVERVIEW_QUERIES_ENUMS.REVENUES])
              ? formatUtils.formatIntegerCurrency(
                  payload[0]?.payload?.current,
                  payload[0]?.payload?.locale,
                  payload[0]?.payload?.currency
                )
              : payload[0]?.payload?.current}
          </Text>
        </Stack>
      </Stack>
    );
  }
  return <Box />;
};

export default EvolutionTooltip;
