import SearchIcon from "@/assets/icons/Search";
import { StyledTextField } from "@/includes";
import { InputAdornment, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchBarProps } from "./types";

const SearchBar = ({
  search,
  setSearch,
  placeholder = "search_by_name",
  sx = {},
}: SearchBarProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledTextField
      onChange={(e: React.ChangeEvent<any>) => setSearch(e.target.value)}
      value={search}
      variant="outlined"
      size="small"
      sx={{
        width: 1,
        "& input": { textOverflow: "ellipsis", whiteSpace: "nowrap" },
        "& .MuiInputBase-root": {
          mt: 0,
        },
        "& .MuiInputBase-root fieldset": {
          borderColor: theme.customPalette.grey.borderHome,
        },
        "& .MuiInputBase-root:hover": {
          backgroundColor: theme.customPalette.grey.white,
        },
        "& .MuiInputBase-root.Mui-focused": {
          backgroundColor: theme.customPalette.bluePurple.slighty,
        },
        "& .MuiInputBase-root.Mui-focused fieldset": {
          borderColor: theme.customPalette.bluePurple.light,
          borderWidth: 1,
        },
        ...sx,
      }}
      placeholder={t(placeholder)}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ "& svg": { width: 16, height: 16 } }}
          >
            <SearchIcon color={theme.customPalette.grey.light} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
