import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query getCategories(
    $catalogId: CatalogID!
    $first: Int!
    $cursor: String
    $search: String
  ) {
    categories(
      catalogId: $catalogId
      first: $first
      after: $cursor
      search: $search
      sort: "title"
    ) {
      edges {
        node {
          id
          title
          handle
          level
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_SEARCH_COLUMN = gql`
  query searchColumn(
    $siteId: SiteID!
    $column: String!
    $search: String
    $first: Int!
    $cursor: String
  ) {
    searchColumn(
      siteId: $siteId
      column: $column
      first: $first
      search: $search
      after: $cursor
    ) {
      edges {
        node {
          name
          data
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
