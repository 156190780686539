import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  CHANGE_SITE,
  HIDE_NOTIFICATION,
  LOGOUT,
  SEND_NOTIFICATION,
} from "../actions/action-types";

export const initialState = {
  title: "",
  text: "",
  name: "",
  open: false,
  severity: "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SEND_NOTIFICATION:
        draft.name = action.payload.name;
        draft.text = action.payload.text;
        draft.title = action.payload.title;
        draft.severity = action.payload.severity;
        draft.open = true;
        break;
      case HIDE_NOTIFICATION:
        draft.name = "";
        draft.text = "";
        draft.title = "";
        draft.open = false;
        draft.severity = "";

        break;

      case LOGOUT:
        draft.title = "";
        draft.text = "";
        draft.name = "";
        draft.open = false;
        draft.severity = "";

        break;
      default:
        break;
    }
  });
}
