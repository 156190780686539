import { Loader, Text } from "@/includes";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { formatUtils } from "@/utils";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEndDate, getStartDate } from "../../helpers";
import { formatDataToOverview } from "./formatters";
import { GET_OVERVIEW } from "./requests";
import { OverviewProps, OverviewType, ResponseOverviewDataType } from "./types";

const Overview = ({ columnToShow, experience }: OverviewProps) => {
  const theme = useTheme();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const devices = [
    experience?.onMobile ? "mobile" : "",
    experience?.onDesktop ? "desktop" : "",
  ]?.filter((device: string) => !isEmpty(device));

  useEffect(() => {
    if (experience) {
      setStartDate(getStartDate(experience?.deployedAt));
      setEndDate(getEndDate());
    }
  }, [experience]);

  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [loading, setLoading] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<OverviewType>();

  const getData = () => {
    setLoading(StatusEnum.LOADING);
    client
      .query({
        query: GET_OVERVIEW,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          segmentExposedVisitors: `potions;${experience.type?.toLowerCase()};${experience.analyticsName?.toLowerCase()};all`,
          segmentUsers: `potions;${experience.type?.toLowerCase()};${experience.analyticsName?.toLowerCase()};convert`,
          devices: devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseOverviewDataType) => {
        setData(formatDataToOverview(res?.data));
        setLoading(StatusEnum.NORMAL);
      })
      .catch(() => setLoading(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (!isEmpty(experience) && !isEmpty(startDate) && !isEmpty(endDate)) {
      getData();
    }
  }, [startDate, endDate, experience]);

  if (loading === StatusEnum.LOADING) {
    return (
      <Loader
        customStyle={{ width: theme.spacing(4), height: theme.spacing(4) }}
      />
    );
  }

  if (columnToShow === "users_over_exposed") {
    return <Text>{formatUtils.formatToPercent(data?.visitors ?? 0)}</Text>;
  }

  if (columnToShow === "revenues_from_users") {
    return <Text>{formatUtils.formatToPercent(data?.revenues ?? 0)}</Text>;
  }

  if (columnToShow === "revenues_per_users_over_revenues_per_exposed") {
    return (
      <Text>{formatUtils.formatMultiply(data?.revenue_per_user ?? 0, 2)}</Text>
    );
  }

  return <Text>-</Text>;
};

export default Overview;
