import { FlagshipProvider } from "@flagship.io/react-sdk";
import { usePendo } from "@hooks";
import Intercom from "@intercom/messenger-js-sdk";
import { potionsLocalStorage } from "@localStorage";
import { Stack } from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { RootState } from "@store";
import { VERSION } from "@utils";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import "react-virtualized/styles.css";
import Router from "./components/_Organisms/Router";
import AnalyticsWrapper from "./components/_Templates/AnalyticsWrapper";
import CatalogWrapper from "./components/_Templates/CatalogWrapper";
import useGetCurrency from "./hooks/useGetCurrency";
import { getUserInfos } from "./services/redux/actions";
import { abTastyTheme } from "./theme";

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);

const checkVersion = () => {
  const localStorageVersion = potionsLocalStorage.version;
  const localSiteID = potionsLocalStorage.siteId;
  if (VERSION !== localStorageVersion) {
    try {
      if (!!localSiteID) {
        localStorage.clear();
        potionsLocalStorage.set("version", VERSION);

        window.location.href = `${window.location.origin}/login`;
      } else {
        potionsLocalStorage.set("version", VERSION);
      }
    } catch (e: any) {}
  }
};

const App: React.FC = () => {
  usePendo();
  useGetCurrency();

  const siteId = useSelector((state: RootState) => state.site.siteId);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const email = useSelector((state: RootState) => state.login.user.email);
  const userId = useSelector((state: RootState) => state.login.user.id);
  const appTheme = abTastyTheme;

  const APP_ID = "y9zltgjv";
  Intercom({
    app_id: APP_ID,
    email: email,
    user_id: userId,
    created_at: Date.now(),
    hide_default_launcher: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // initialize GA
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
    script.setAttribute("async", "");
    checkVersion();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserInfos());
    }
  }, [siteId, isLoggedIn]);

  return (
    <FlagshipProvider
      envId={process.env.REACT_APP_FLAGSHIP_ENVIRONNEMENT_ID ?? ""}
      apiKey={process.env.REACT_APP_FLAGSHIP_API_KEY ?? ""}
      visitorData={{
        hasConsented: true,
        context: {
          siteId,
          siteIdNumber: parseInt(siteId),
        },
      }}
    >
      <ThemeProvider theme={responsiveFontSizes(appTheme, { factor: 5 })}>
        <AnalyticsWrapper>
          <CatalogWrapper>
            <Stack
              sx={{
                height: 1,
                width: 1,
                backgroundColor: appTheme.customPalette.others.background,
                "& ::-webkit-scrollbar": {
                  width: "14px",
                },

                "& ::-webkit-scrollbar-track": {
                  background: appTheme.customPalette.others.background,
                },

                "& ::-webkit-scrollbar-thumb": {
                  background: appTheme.customPalette.grey.disabled,
                  backgroundClip: "padding-box",
                  border: "4px solid transparent",
                  borderRadius: "8px",
                },

                "& #no-scroll::-webkit-scrollbar": {
                  display: "none",
                },

                "& #no-scroll": {
                  msOverflowStyle: "none",
                  scrollBehavior: "smooth",
                },

                /* Chrome, Safari, Edge, Opera */
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none !important",
                    margin: "0 !important",
                  },

                /* Firefox */
                '& input[type="number"]': {
                  MozAppearance: "textfield !important",
                },

                '& div[role="presentation"].MuiAutocomplete-popper': {
                  ZIndex: 1500,
                },
              }}
            >
              <Router />
            </Stack>
          </CatalogWrapper>
        </AnalyticsWrapper>
      </ThemeProvider>
    </FlagshipProvider>
  );
};

export default App;
