import { RootState } from "@/services/redux/store";
import { usePersistedState, useViewport } from "@hooks";
import {
  Box,
  DraggableGridView,
  PreviewViewSettings,
  SideModal,
} from "@includes";
import { Divider, Stack } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingOverlay from "../LoadingOverlay";
import NoRowsOverlay from "../NoRowsOverlay";
import ProductCard from "../ProductCard";
import { IProducts } from "./types";
import { useLocale } from "@/context/LocaleProvider";

const getProduct = (
  productPerRow: number,
  unpin: any,
  pin: any,
  setProductIndex: (index: number) => void,
  pinnable: boolean,
  locale: string = "fr",
  currency: string = "€"
) => {
  return (product: Dic<any>, index: number) => {
    return (
      <ProductCard
        setProductIndex={() => setProductIndex(index + 1)}
        product={product}
        productPerRow={productPerRow}
        showUnpin={!!product?.isPinned}
        unpin={() => unpin(product?.id)}
        pin={() => pin(product?.id, index + 1)}
        pinnable={pinnable}
        locale={locale}
        currency={currency}
      />
    );
  };
};

const grid = (
  device: Device,
  products: Array<Dic<any>>,
  nbProductToShow: number,
  pushProduct: (id: string, index: number) => void,
  unpin: any,
  pin: any,
  handleOpen: (index: number) => void,
  setNbProductToShow: (nb: number) => void,
  pinnable: boolean,
  locale: string = "fr",
  currency: string = "€"
) => {
  let toShow = <></>;
  if (device === "desktop") {
    toShow = (
      <DraggableGridView
        itemsPerRow={4}
        items={products}
        nbItemToShow={nbProductToShow}
        child={getProduct(
          4,
          unpin,
          pin,
          handleOpen,
          pinnable,
          locale,
          currency
        )}
        pushProduct={pushProduct}
        totalItems={products?.length}
        setNbProductToShow={setNbProductToShow}
      />
    );
  } else if (device === "mobile") {
    toShow = (
      <DraggableGridView
        itemsPerRow={2}
        items={products}
        nbItemToShow={nbProductToShow}
        child={getProduct(
          2,
          unpin,
          pin,
          handleOpen,
          pinnable,
          locale,
          currency
        )}
        pushProduct={pushProduct}
        totalItems={products?.length}
        setNbProductToShow={setNbProductToShow}
      />
    );
  } else {
    toShow = (
      <Stack direction="row" spacing={2}>
        <DraggableGridView
          itemsPerRow={3}
          items={products}
          nbItemToShow={nbProductToShow}
          child={getProduct(
            3,
            unpin,
            pin,
            handleOpen,
            pinnable,
            locale,
            currency
          )}
          pushProduct={pushProduct}
          totalItems={products?.length}
          setNbProductToShow={setNbProductToShow}
        />
        <Divider />
        <DraggableGridView
          itemsPerRow={2}
          items={products}
          nbItemToShow={nbProductToShow}
          child={getProduct(
            2,
            unpin,
            pin,
            handleOpen,
            pinnable,
            locale,
            currency
          )}
          pushProduct={pushProduct}
          totalItems={products?.length}
          setNbProductToShow={setNbProductToShow}
        />
      </Stack>
    );
  }

  return <>{toShow}</>;
};

const Products: React.FC<IProducts> = ({
  isLoading,
  products,
  nbProductToShow,
  productsPerRow,
  setNbProductToShow,
  setProductsPerRow,
  list,
  pin,
  pushProduct,
  unpin,
  categoryId,
  pinnable,
}) => {
  const [openSearchProduct, setOpenSearchProduct] = useState<number | null>(
    null
  );

  const { isMobile } = useViewport();

  const handleOpen = (index: number) => setOpenSearchProduct(index);

  const handleClose = () => setOpenSearchProduct(null);

  const [device, setDevice] = usePersistedState({
    key: "device",
    initialValue: isMobile ? "mobile" : "desktop",
  });

  const handleChangeDevice = (device: Device) => {
    setDevice(device);
  };

  const deviceObj = { device, handleChangeDevice };

  const push = (product: Dic<any>) => {
    pushProduct(product?.id, (openSearchProduct ?? 1) + 1);
    handleClose();
  };
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { locale } = useLocale();

  if (isLoading && products?.length <= 0) return <LoadingOverlay list={list} />;

  return (
    <>
      <SideModal
        isOpen={!!openSearchProduct}
        onClose={handleClose}
        child="search_product"
        position="right"
        params={{
          addProduct: push,
          param: { categoryId },
        }}
      />
      {products?.length === 0 && !isLoading ? (
        <NoRowsOverlay list={list} />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            mt={2}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <PreviewViewSettings deviceObj={deviceObj} />
            </Stack>
          </Stack>
          <Box
            sx={{
              opacity: isLoading ? 0.5 : 1,
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            {grid(
              device,
              products,
              nbProductToShow,
              pushProduct,
              unpin,
              pin,
              handleOpen,
              setNbProductToShow,
              pinnable,
              locale,
              currency
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Products;
