import { IHasPages } from "@/utils/types/component";
import { useHasProperty } from "@hooks";

const HasReportOrABTestWrapper: React.FC<IHasPages> = ({ children }) => {
  const { hideReport, hasAbtest } = useHasProperty();

  if (!hideReport || hasAbtest) {
    return <>{children}</>;
  }

  return <></>;
};

export default HasReportOrABTestWrapper;
