import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputAdornment, useTheme, Stack } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { useReport } from "../../hooks/useReport";
import { Loader, StyledTextField } from "@includes";
import { experiencesUtils } from "@utils";
import { RootState } from "@store";
import { IDateSelector } from "./types";

const getPaperStyle = (theme: any) => ({
  background: theme.customPalette.others.layer_1,
  border: "1px solid",
  borderColor: theme.customPalette.others.background,
  "& .MuiPickersDay-root": {
    background: "transparent",
  },
  "& .MuiPickersDay-root:hover": {
    background: theme.customPalette.grey.white,
  },
  "& .MuiPickersDay-root.Mui-disabled": {
    color: theme.customPalette.others.background,
  },
  "& .MuiPickersDay-root.Mui-selected": {
    background: theme.customPalette.others.background,
    color: theme.customPalette.grey.default,
  },
  "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
    background: theme.customPalette.others.layer_2,
  },
  "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
    color: theme.customPalette.grey.default,
  },
});

const startDateSelector = (state: any) => state.context.startDate;

const endDateSelector = (state: any) => state.context.endDate;

const DateSelector: React.FC<IDateSelector> = ({ dateMep }) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const analyticsType = useSelector((state: RootState) => state.analytics.type);

  const reportServices = useReport();

  const { send } = reportServices.reportService;

  const startDate = useXStateSelector(
    reportServices.reportService,
    startDateSelector
  );

  const endDate = useXStateSelector(
    reportServices.reportService,
    endDateSelector
  );

  const [notValidatedDateRange, setNotValidatedDateRange] = React.useState<
    DateRange<Dayjs>
  >([null, dayjs().subtract(2, "days")]);

  const validateNewDateRange = (newStartDate: Dayjs, newEndDate: Dayjs) => {
    if (
      dayjs(newEndDate).diff(dayjs(newStartDate), "day", true) <
      experiencesUtils.getMaximumRange(analyticsType)
    ) {
      send({
        type: "CHANGE_DATE_RANGE",
        data: {
          startDate: dayjs(newStartDate).format("YYYY-MM-DD"),
          endDate: dayjs(newEndDate).format("YYYY-MM-DD"),
        },
      });
    } else {
      send({
        type: "CHANGE_DATE_RANGE",
        data: {
          startDate: dayjs(newEndDate)
            .subtract(experiencesUtils.getMaximumRange(analyticsType), "days")
            .format("YYYY-MM-DD"),
          endDate: dayjs(newEndDate).format("YYYY-MM-DD"),
        },
      });
    }
  };

  const [dateToDisplay, setDateToDisplay] = React.useState<any>([]);

  React.useEffect(() => {
    if (startDate && endDate) {
      setDateToDisplay([
        dayjs(startDate).format("L"),
        dayjs(endDate).format("L"),
      ]);
      if (!notValidatedDateRange[0]) {
        setNotValidatedDateRange([startDate, endDate]);
      }
    }
  }, [startDate, endDate]);

  const paperStyle = React.useMemo(() => getPaperStyle(theme), [theme]);

  if (!startDate || !endDate) {
    return <Loader />;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: t("start_date"), end: t("end_date") }}
    >
      <DateRangePicker
        PaperProps={{
          sx: { ...paperStyle },
        }}
        minDate={dateMep}
        maxDate={dayjs().subtract(2, "days")}
        onAccept={(newValue: any) =>
          validateNewDateRange(dayjs(newValue[0]), dayjs(newValue[1]))
        }
        inputFormat="DD/MM/YYYY"
        value={notValidatedDateRange}
        onChange={(newValue: any) => {
          setNotValidatedDateRange([dayjs(newValue[0]), dayjs(newValue[1])]);
        }}
        renderInput={(startProps: any, endProps: any) => (
          <Stack direction="row" spacing={2}>
            <StyledTextField
              name="start date"
              variant="outlined"
              value={dateToDisplay[0]}
              {...startProps}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={`/${theme.customPalette.mode}/report/dateRange.svg`}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              name="end date"
              variant="outlined"
              value={dateToDisplay[1]}
              {...endProps}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={`/${theme.customPalette.mode}/report/dateRange.svg`}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateSelector;
