import React, { createContext, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

require("dayjs/locale/fr");
require("dayjs/locale/en");
require("dayjs/locale/en-gb");
require("dayjs/locale/de");
require("dayjs/locale/es");
require("dayjs/locale/it");
require("dayjs/locale/ja");
require("dayjs/locale/pt");

dayjs.extend(localizedFormat);

interface LocaleContextType {
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

export const LocaleContext = createContext({} as LocaleContextType);

export default function LocaleProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [locale, setLocale] = useState("en");
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  return useContext(LocaleContext);
}
