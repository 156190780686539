import TargetedPopups from "@/components/_Routes/TargetedPopups";
import NoMenuWrapper from "@/components/_Templates/NoMenuWrapper";
import { useHasProperty } from "@/hooks";
import { lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import FollowExperienceRunWrapper from "../../_Templates/FollowExperienceRunWrapper";
import GenerateResourcesWrapper from "../../_Templates/GenerateResourcesWrapper";
import RedirectionWrapper from "../../_Templates/RedirectionWrapper";
import { AuthWrapper, SideMenuWrapper } from "../../Wrappers";
import ScrollToTop from "./components/ScrollToTop";

const CreateAccount = lazy(() => import("../../_Routes/CreateAccount"));
const Home = lazy(() => import("../../_Routes/Home"));
const Rule = lazy(() => import("../../_Routes/Rule"));
const Rules = lazy(() => import("../../_Routes/Rules"));
const Login = lazy(() => import("../../_Routes/Login"));
const Signup = lazy(() => import("../../_Routes/Signup"));
const Collections = lazy(() => import("../../_Routes/Collections"));
const Placements = lazy(() => import("../../_Routes/Placements"));
const Catalog = lazy(() => import("../../_Routes/Catalog"));
const Campaigns = lazy(() => import("../../_Routes/Campaigns"));
const ConfigureCampaigns = lazy(() =>
  import("../../_Routes/ConfigureCampaigns")
);
const NotFound = lazy(() => import("../../_Routes/NotFound"));
const NotAccount = lazy(() => import("../../_Routes/NotAccount"));
const VerifyEmail = lazy(() => import("../../_Routes/VerifyEmail"));
const Report = lazy(() => import("../../_Routes/Report"));
const Settings = lazy(() => import("../../_Routes/Settings"));
const ConfigureMerchandising = lazy(() =>
  import("../../_Routes/ConfigureMerchandising")
);
const ConfigureRecentlyViewedProducts = lazy(() =>
  import("../../_Routes/RecentlyViewedProducts/Tabs")
);
const ConfigureEmailing = lazy(() => import("../../_Routes/Emailing"));
const ConfigureRecommendations = lazy(() =>
  import("../../_Routes/ConfigureRecommendations")
);
const ConfigureLastSessionRecovery = lazy(() =>
  import("../../_Routes/LastSessionRecovery/Tabs")
);
const ConfigureTargetedPopup = lazy(() =>
  import("../../_Routes/TargetedPopups/Tabs")
);
const Datasets = lazy(() => import("../../_Routes/Datasets"));
const ResetPassword = lazy(() => import("../../_Routes/ResetPassword"));
const RecommendationsSite = lazy(() =>
  import("../../_Routes/RecommendationsSite")
);
const RecommendationsMail = lazy(() =>
  import("../../_Routes/RecommendationsMail")
);
const RecentlyViewedProducts = lazy(() =>
  import("../../_Routes/RecentlyViewedProducts")
);
const AutomaticAuthentication = lazy(() =>
  import("../../_Routes/AutomaticAuthentication/AutomaticAuthentication")
);
const LastSessionRecovery = lazy(() =>
  import("../../_Routes/LastSessionRecovery")
);
const Collection = lazy(() => import("../../_Routes/Collection/Main"));
const Merchandising = lazy(() => import("../../_Routes/Merchandising"));
const TargetedPopups = lazy(() => import("../../_Routes/TargetedPopups"));
const SuperAdmin = lazy(() => import("../../_Routes/SuperAdmin"));
const Variables = lazy(() => import("../../_Routes/Variables"));
const Templates = lazy(() => import("../../_Routes/Templates"));
const ItemTemplateBuilder = lazy(() =>
  import("../../_Routes/ItemTemplateBuilder")
);
const ConfigureVariable = lazy(() => import("../../_Routes/ConfigureVariable"));
const Algorithms = lazy(() => import("../../_Routes/Algorithms"));
const RecommendationBuilder = lazy(() =>
  import("../../_Routes/RecommendationBuilder")
);
const MerchandisingBuilder = lazy(() =>
  import("../../_Routes/MerchandisingBuilder")
);
const ConfigureAlgorithm = lazy(() =>
  import("../../_Routes/ConfigureAlgorithm")
);
const RecommendationSettings = lazy(() =>
  import("../../_Routes/RecommendationSettings")
);
const Fields = lazy(() => import("../../_Routes/Fields"));
const FieldBuilder = lazy(() => import("../../_Routes/FieldBuilder"));
const RecommendationTemplateBuilder = lazy(() =>
  import("../../_Routes/RecommendationTemplateBuilder")
);

const sideMenu = (wrappedComponent) => (
  <SideMenuWrapper wrappedComponent={wrappedComponent} />
);

const noMenu = (wrappedComponent) => (
  <NoMenuWrapper wrappedComponent={wrappedComponent} />
);

export function AuthenticatedRoute({ component }) {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  return isLoggedIn ? <>{component}</> : <Redirect to="/login" />;
}

export function SuperAdminRoute({ component }) {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const devMode = useSelector((state) => state.developerMode.isEnabled);

  return isLoggedIn && devMode ? <>{component}</> : <Redirect to="/" />;
}

const Router = () => {
  const { hideReport } = useHasProperty();
  return (
    <BrowserRouter>
      <RedirectionWrapper>
        <GenerateResourcesWrapper>
          <FollowExperienceRunWrapper>
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <NoMenuWrapper wrappedComponent={Login} />
              </Route>
              <Route exact path="/login">
                <NoMenuWrapper wrappedComponent={Login} />
              </Route>
              <Route path="/signup">
                <AuthWrapper wrappedComponent={Signup} />
              </Route>
              <Route path="/verify">
                <AuthWrapper wrappedComponent={VerifyEmail} />
              </Route>
              <Route exact path="/password/reset">
                <AuthWrapper wrappedComponent={ResetPassword} />
              </Route>
              <Route exact path="/create_account">
                <AuthWrapper wrappedComponent={CreateAccount} />
              </Route>

              <Route exact path="/:accountId/:siteId/collections">
                <AuthenticatedRoute component={sideMenu(Collections)} />
              </Route>
              <Route exact path="/:accountId/:siteId/recommendation_builder">
                <AuthenticatedRoute component={noMenu(RecommendationBuilder)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recommendation_builder/:experienceId"
              >
                <AuthenticatedRoute component={noMenu(RecommendationBuilder)} />
              </Route>
              <Route exact path="/:accountId/:siteId/merchandising_builder">
                <AuthenticatedRoute component={noMenu(MerchandisingBuilder)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/merchandising_builder/:experienceId"
              >
                <AuthenticatedRoute component={noMenu(MerchandisingBuilder)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/manual_collection/:collectionId"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/manual_collection"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/composed_collection/:collectionId"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/composed_collection"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/dynamic_collection/:collectionId"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/collections/dynamic_collection"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route path="/:accountId/:siteId/settings">
                <AuthenticatedRoute component={sideMenu(Settings)} />
              </Route>
              {!hideReport && (
                <Route exact path="/:accountId/:siteId/report">
                  <AuthenticatedRoute component={sideMenu(Report)} />
                </Route>
              )}
              <Route exact path="/:accountId/:siteId/catalog">
                <AuthenticatedRoute component={sideMenu(Catalog)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/merchandising/:experienceId"
              >
                <AuthenticatedRoute
                  component={sideMenu(ConfigureMerchandising)}
                />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recommendations/emailing/:experienceId"
              >
                <AuthenticatedRoute component={sideMenu(ConfigureEmailing)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recommendations/site/:experienceId"
              >
                <AuthenticatedRoute
                  component={sideMenu(ConfigureRecommendations)}
                />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recently_viewed_products/:experienceId"
              >
                <AuthenticatedRoute
                  component={sideMenu(ConfigureRecentlyViewedProducts)}
                />
              </Route>
              <Route exact path="/:accountId/:siteId/home">
                <AuthenticatedRoute component={sideMenu(Home)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/targeted_popups/:experienceId"
              >
                <AuthenticatedRoute
                  component={sideMenu(ConfigureTargetedPopup)}
                />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/last_session_recovery/:experienceId"
              >
                <AuthenticatedRoute
                  component={sideMenu(ConfigureLastSessionRecovery)}
                />
              </Route>

              <Route exact path="/:accountId/:siteId/recommendations/site">
                <AuthenticatedRoute component={sideMenu(RecommendationsSite)} />
              </Route>
              <Route exact path="/:accountId/:siteId/recommendations/emailing">
                <AuthenticatedRoute component={sideMenu(RecommendationsMail)} />
              </Route>

              <Route exact path="/:accountId/:siteId/targeted_popups">
                <AuthenticatedRoute component={sideMenu(TargetedPopups)} />
              </Route>
              <Route exact path="/:accountId/:siteId/recently_viewed_products">
                <AuthenticatedRoute
                  component={sideMenu(RecentlyViewedProducts)}
                />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/merchandising/collection/:collectionId"
              >
                <AuthenticatedRoute component={sideMenu(Collection)} />
              </Route>
              <Route exact path="/:accountId/:siteId/merchandising">
                <AuthenticatedRoute component={sideMenu(Merchandising)} />
              </Route>

              <Route exact path="/:accountId/:siteId/last_session_recovery">
                <AuthenticatedRoute component={sideMenu(LastSessionRecovery)} />
              </Route>
              <Route exact path="/:accountId/:siteId/automatic_authentication">
                <AuthenticatedRoute
                  component={sideMenu(AutomaticAuthentication)}
                />
              </Route>
              <Route path="/:accountId/:siteId/super_admin">
                <AuthenticatedRoute component={sideMenu(SuperAdmin)} />
              </Route>
              <Route path="/:accountId/:siteId/placements">
                <AuthenticatedRoute component={sideMenu(Placements)} />
              </Route>
              <Route exact path="/:accountId/:siteId/campaigns/:campaignId">
                <AuthenticatedRoute component={sideMenu(ConfigureCampaigns)} />
              </Route>
              <Route path="/:accountId/:siteId/campaigns">
                <AuthenticatedRoute component={sideMenu(Campaigns)} />
              </Route>
              <Route path="/:accountId/:siteId/datasets/:datasetId/:fieldId">
                <AuthenticatedRoute component={noMenu(FieldBuilder)} />
              </Route>
              <Route path="/:accountId/:siteId/datasets/:datasetId">
                <AuthenticatedRoute component={sideMenu(Fields)} />
              </Route>
              <Route path="/:accountId/:siteId/datasets">
                <AuthenticatedRoute component={sideMenu(Datasets)} />
              </Route>
              <Route exact path="/:accountId/:siteId/rules">
                <AuthenticatedRoute component={sideMenu(Rules)} />
              </Route>
              <Route exact path="/:accountId/:siteId/rules/:ruleId">
                <AuthenticatedRoute component={sideMenu(Rule)} />
              </Route>
              <Route exact path="/:accountId/:siteId/variables/:variableId">
                <AuthenticatedRoute component={sideMenu(ConfigureVariable)} />
              </Route>
              <Route exact path="/:accountId/:siteId/variables">
                <AuthenticatedRoute component={sideMenu(Variables)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/item_template_builder/:templateId"
              >
                <AuthenticatedRoute component={noMenu(ItemTemplateBuilder)} />
              </Route>
              <Route exact path="/:accountId/:siteId/item_template_builder">
                <AuthenticatedRoute component={noMenu(ItemTemplateBuilder)} />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recommendation_template_builder/:templateId"
              >
                <AuthenticatedRoute
                  component={noMenu(RecommendationTemplateBuilder)}
                />
              </Route>
              <Route
                exact
                path="/:accountId/:siteId/recommendation_template_builder"
              >
                <AuthenticatedRoute
                  component={noMenu(RecommendationTemplateBuilder)}
                />
              </Route>
              <Route exact path="/:accountId/:siteId/templates">
                <AuthenticatedRoute component={sideMenu(Templates)} />
              </Route>
              <Route exact path="/:accountId/:siteId/algorithms/:algorithmId">
                <AuthenticatedRoute component={sideMenu(ConfigureAlgorithm)} />
              </Route>
              <Route exact path="/:accountId/:siteId/algorithms">
                <AuthenticatedRoute component={sideMenu(Algorithms)} />
              </Route>

              <Route
                exact
                path="/:accountId/:siteId/recommendations/settings/:experienceId"
              >
                <SuperAdminRoute component={sideMenu(RecommendationSettings)} />
              </Route>
              <Route path="/not_account">
                <NotAccount />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </FollowExperienceRunWrapper>
        </GenerateResourcesWrapper>
      </RedirectionWrapper>
    </BrowserRouter>
  );
};

export default Router;
