import { useLocale } from "@/context/LocaleProvider";
import { client } from "@/services/graphql/apolloConfig";
import {
  setCron,
  setDefaultAnalyticsIntegrationId,
  setDefaultCmsIntegrationId,
  setDefaultEmailingIntegrationId,
  setHasAbTest,
  setHasBuilder,
  setHasEmotionAi,
  setHasSkuReport,
  setHideCatalog,
  setHideMerch,
  setHideReport,
} from "@/services/redux/actions/site-actions";
import { FLAGS_ENUM } from "@/utils/enums/flags";
import { gql } from "@apollo/client";
import { useFsFlag } from "@flagship.io/react-sdk";
import { useHasExperiences, useIsMounted } from "@hooks";
import { RootState } from "@store";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGraphQlLazyQuery from "./useGraphqlLazyQuery";

const GET_HAS_PAGES = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      id
      defaultEmailingIntegrationId
      defaultCmsIntegrationId
      defaultAnalyticsIntegrationId
      cron
      locale
    }
  }
`;

const GET_VALUE_OF_FLAG = gql`
  query FlagByName($siteId: SiteID!, $name: String!) {
    flagByName(siteId: $siteId, name: $name) {
      key
      value
    }
  }
`;

const GET_ACTIVE_MULTIPLY_EXPERIENCES = gql`
  query getExperiences($siteId: SiteID) {
    experiences(
      siteId: $siteId
      searchType: "MULTIPLY"
      filters: "is_monitorable = true AND deployed_at != null"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

/**
 * Custom React hook to check various properties related to a site.
 *
 * @returns Object containing various properties of the site.
 */
const useHasProperty = () => {
  const isMounted = useIsMounted();
  const { hasExperiences } = useHasExperiences();
  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );
  const { setLocale } = useLocale();
  const dispatch = useDispatch();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const cron: string = useSelector((state: RootState) => state.site.cron);
  const hasAbtest: boolean = useSelector(
    (state: RootState) => state.site.hasAbtest
  );
  const hasSkuReport: boolean = useSelector(
    (state: RootState) => state.site.hasSkuReport
  );
  const flagHasSkuReport = useFsFlag(FLAGS_ENUM.HAS_SKU_REPORT);
  const flagValueHasSkuReport = flagHasSkuReport.getValue(false);

  const hideCatalog: boolean = useSelector(
    (state: RootState) => state.site.hideCatalog
  );
  const flagHideCatalog = useFsFlag(FLAGS_ENUM.HIDE_CATALOG);
  const flagValueHideCatalog = flagHideCatalog.getValue(false);

  const isSuperUser: boolean = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const hasMember: boolean =
    isSuperUser || !["561", "504"].includes((siteId ?? "")?.toString());

  const defaultEmailingIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultEmailingIntegrationId
  );
  const defaultCmsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultCmsIntegrationId
  );
  const defaultAnalyticsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultAnalyticsIntegrationId
  );

  const hideMerch: boolean = useSelector(
    (state: RootState) => state.site.hideMerch
  );
  const flagHideMerch = useFsFlag(FLAGS_ENUM.HIDE_MERCH);
  const flagValueHideMerch = flagHideMerch.getValue(false);

  const hideReport: boolean = useSelector(
    (state: RootState) => state.site.hideReport
  );
  const flagHideReport = useFsFlag(FLAGS_ENUM.HIDE_REPORT);
  const flagValueHideReport = flagHideReport.getValue(false);

  const hasEmotionAi: boolean = useSelector(
    (state: RootState) => state.site.hasEmotionAi
  );
  const flagHasEmotionAI = useFsFlag(FLAGS_ENUM.HAS_EMOTION_AI);
  const flagValueHasEmotionAI = flagHasEmotionAI.getValue(false);

  const hasBuilder: boolean = useSelector(
    (state: RootState) => state.site.hasBuilder
  );
  const flagHasBuilder = useFsFlag(FLAGS_ENUM.HAS_BUILDER);
  const flagValueHasBuilder = flagHasBuilder.getValue(false);

  const isNewLab = false;

  const {
    getDatas,
    data,
    loading: gettingHasPages,
  } = useGraphQlLazyQuery(GET_HAS_PAGES, { siteId }, "no-cache");

  // Custom hook to fetch data using GraphQL queries
  const {
    getDatas: getActiveExperiences,
    data: activeExperiences,
    loading: gettingExperiences,
  } = useGraphQlLazyQuery(
    GET_ACTIVE_MULTIPLY_EXPERIENCES,
    { siteId: parseInt(siteId) },
    "cache-first"
  );

  useEffect(() => {
    if (siteId && isMounted) {
      getDatas();
      getActiveExperiences();
    }
  }, [siteId]);

  const getValueOfFlag = async (
    flagKey: string,
    siteIdentifier: string
  ): Promise<any> => {
    try {
      const res = await client.query({
        query: GET_VALUE_OF_FLAG,
        variables: { siteId: siteIdentifier, name: flagKey },
      });
      return res?.data?.flagByName?.value ?? false;
    } catch (error) {
      console.error("Error fetching flag value:", error);
      throw error;
    }
  };

  // Effect to update Redux store based on fetched data
  useEffect(() => {
    if (data && isMounted) {
      dispatch(
        setDefaultEmailingIntegrationId(
          data?.site?.defaultEmailingIntegrationId
        )
      );
      dispatch(
        setDefaultAnalyticsIntegrationId(
          data?.site?.defaultAnalyticsIntegrationId
        )
      );
      dispatch(setDefaultCmsIntegrationId(data?.site?.defaultCmsIntegrationId));
      dispatch(setCron(data?.site?.cron));
      setLocale(
        !isEmpty(data?.site?.locale) ? data.site.locale : navigator.language
      );
    }
  }, [data]);

  const refreshProperties = () => getDatas();

  useEffect(() => {
    if (flagValueHasEmotionAI !== hasEmotionAi)
      dispatch(setHasEmotionAi(flagValueHasEmotionAI));
    if (flagValueHideCatalog !== hideCatalog)
      dispatch(setHideCatalog(flagValueHideCatalog));
    if (flagValueHasSkuReport !== hasSkuReport)
      dispatch(setHasSkuReport(flagValueHasSkuReport));
    if (flagValueHideReport !== hideReport)
      dispatch(setHideReport(flagValueHideReport));
    if (flagValueHasBuilder !== hasBuilder)
      dispatch(setHasBuilder(flagValueHasBuilder));
  }, [
    flagValueHasEmotionAI,
    flagValueHideCatalog,
    flagValueHasSkuReport,
    flagValueHideReport,
    flagValueHasBuilder,
  ]);

  useEffect(() => {
    if ((flagValueHideMerch && !devMode) !== hideMerch)
      dispatch(setHideMerch(flagValueHideMerch && !devMode));
  }, [flagValueHideMerch, devMode]);

  useEffect(() => {
    getValueOfFlag(FLAGS_ENUM.HAS_ABTEST, siteId).then((value: boolean) => {
      if (value !== hasAbtest) dispatch(setHasAbTest(value));
    });
  }, []);

  return {
    refreshProperties,
    gettingHasPages,
    hasAbtest,
    hasSkuReport,
    hideCatalog,
    defaultEmailingIntegrationId,
    defaultAnalyticsIntegrationId,
    defaultCmsIntegrationId,
    hasCmsTools: !!defaultCmsIntegrationId,
    hasAnalyticsTools: !!defaultAnalyticsIntegrationId,
    loading: gettingExperiences || gettingHasPages,
    hasEmail: !!defaultEmailingIntegrationId,
    isNewLab,
    isOldLab: !isNewLab,
    hasMember,
    getValueOfFlag,
    hideMerch,
    hideReport,
    hasEmotionAi,
    hasBuilder,
    cron,
  };
};

export default useHasProperty;
