import NewSlider from "@/components/_Templates/NewSlider";
import { Box } from "@mui/material";
import LoadingOverlay from "../LoadingOverlay";
import NoRowsOverlay from "../NoRowsOverlay";
import { ISlide } from "./types";
import { isEmpty } from "lodash";
import { Error } from "@/includes";

const Slide: React.FC<ISlide> = ({ isLoading, products, list, error = "" }) => {
  if (isLoading) {
    return <LoadingOverlay list={list} />;
  }
  if (!isEmpty(error)) return <Error errorMessage={error} />;
  if (products?.length === 0) return <NoRowsOverlay list={list} />;
  return (
    <Box>
      <NewSlider products={products} />
    </Box>
  );
};

export default Slide;
