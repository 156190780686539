import { Stack, Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Layer2Box, Tag, Text, Tooltip } from "@includes";
import { comparisonUtils, formatUtils, productUtils } from "@utils";
import { RootState } from "@store";
import { IProductCard } from "./types";
import { excludedProps, SLIDE_PROPERTIES } from "./enums";
import { getTraduction } from "@/utils/helpers/traductions";
import AbsoluteLinkIcon from "@/assets/icons/AbsoluteLink";
import ProductImage from "../ProductImage";
import { useLocation } from "react-router";

const ProductCard: React.FC<IProductCard> = ({
  product,
  productPerRow,
  locale,
  currency,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const location = useLocation();

  const isPreview = location?.search?.includes("preview");

  const additionnalProperties = isPreview
    ? SLIDE_PROPERTIES
    : useSelector((state: RootState) => state.product.properties);
  const ref = useRef(null);

  const [boxSize, setBoxSize] = useState(0);

  useEffect(() => {
    if (ref) setBoxSize((ref.current as any).clientWidth);
  }, [productPerRow]);

  const hasPrice =
    additionnalProperties?.find(comparisonUtils.areEquals("price")) &&
    !!product?.price;

  const hasStrokePrice =
    additionnalProperties?.find(comparisonUtils.areEquals("stroke_price")) &&
    !!product?.stroke_price;

  const hasDiscountRate =
    additionnalProperties?.find(comparisonUtils.areEquals("discount_rate")) &&
    !!product?.discount_rate;

  const hasCategory =
    additionnalProperties?.find(comparisonUtils.areEquals("category")) &&
    !!product?.category;

  const hasTags =
    additionnalProperties?.find(comparisonUtils.areEquals("tags")) &&
    !!product?.tags;

  const hasCategoriesIds =
    additionnalProperties?.find(comparisonUtils.areEquals("categories_ids")) &&
    !!product?.categories_ids;

  const productProps = additionnalProperties?.filter(
    (p: string) => !excludedProps.find(comparisonUtils.areEquals(p))
  );

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        width: `calc(${100 / productPerRow}%)`,
        minWidth: `calc(${100 / productPerRow}%)`,
        flex: `calc(${100 / productPerRow}%)`,
        display: "flex",
        justifyContent: "center",
        transition: "all 0.2s ease-in",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        sx={{ width: "100%", height: "100%", px: 0.5 }}
      >
        <Layer2Box
          sx={{
            border: "1px solid",
            color: theme.customPalette.others.background,
            width: "calc(100% - 8px)",
            position: "relative",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            borderRadius: 2,
            boxSizing: "border-box",
            backgroundColor: theme.customPalette.white.main,
          }}
        >
          <Box
            sx={{
              height: 1,
              position: "relative",
            }}
          >
            {!isPreview && hasDiscountRate && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  right: -5,
                  top: 5,
                }}
              >
                <Tag tagVariant="success">
                  {productUtils.formatProductProperties(
                    product?.discount_rate,
                    "discount_rate",
                    locale,
                    currency
                  )}
                </Tag>
              </Box>
            )}
            {!isPreview && product?.isDeleted && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  left: -5,
                  top: 5,
                }}
              >
                <Tooltip title={t("product_deleted_tooltip")}>
                  <Box>
                    <Tag tagVariant="error">{t("product_deleted")}</Tag>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {product?.data?.no_image ? (
              <Box
                sx={{
                  width: "100%",
                  minHeight: 150,
                  maxHeight: 150,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text>{t("no_image")}</Text>
              </Box>
            ) : (
              <ProductImage
                src={product?.img_link ?? "no-image"}
                onDragStart={(e) => {
                  e.preventDefault();
                }}
                inactive={product?.inactive ?? false}
                available={product?.is_recommendable ?? true}
              />
            )}
          </Box>

          <Tooltip title={`${t("name")}: ${product?.name}`}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text variant="body2" noWrap customStyle={{ maxWidth: 1 }}>
                {product?.name}
              </Text>
            </Box>
          </Tooltip>
          {!isPreview && hasCategory && (
            <Tooltip title={`${t("category")}: ${product?.category}`}>
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.category}
                </Text>
              </Box>
            </Tooltip>
          )}
          {!isPreview && hasTags && (
            <Tooltip title={`${t("tags")} : ${product?.tags}`}>
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.tags}
                </Text>
              </Box>
            </Tooltip>
          )}
          {!isPreview && hasCategoriesIds && (
            <Tooltip
              title={`${t("categories_ids")} : ${product?.categories_ids}`}
            >
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.categories_ids}
                </Text>
              </Box>
            </Tooltip>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            {hasPrice && (
              <Text
                variant="body2"
                customStyle={{ color: theme.customPalette.bluePurple.dark }}
              >
                {formatUtils.formatPrice(product?.price, locale, currency)}
              </Text>
            )}
            {!isPreview && hasStrokePrice && (
              <Text
                variant="body2"
                customStyle={{
                  textDecoration: "line-through",
                  color: theme.customPalette.grey.light,
                }}
              >
                {formatUtils.formatPrice(
                  product?.stroke_price,
                  locale,
                  currency
                )}
              </Text>
            )}
          </Stack>
          <Stack>
            {productProps
              ?.filter(
                (property: string) =>
                  property !== "img_link" && property !== "name"
              )
              .map((property: string) => {
                const prop = productUtils.getFormattedProperty(property);

                if (!!product[prop] || typeof product[prop] === "boolean")
                  return (
                    <div key={property}>
                      <Tooltip title={getTraduction(t, property)}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: 1,
                          }}
                        >
                          <Text
                            textType="secondary"
                            variant="caption"
                            noWrap
                            customStyle={{
                              fontWeight: "bold",
                              flexShrink: 0,
                              mr: 0.5,
                              maxWidth: "100%",
                            }}
                          >
                            {getTraduction(t, property)}:
                          </Text>
                          {prop.includes("link") ? (
                            <a
                              href={product[prop]}
                              target="_blank"
                              rel="noopener"
                            >
                              <Box sx={{ "& svg": { height: 10, width: 10 } }}>
                                <AbsoluteLinkIcon
                                  color={theme.customPalette.bluePurple.main}
                                />
                              </Box>
                            </a>
                          ) : (
                            <Text
                              textType="secondary"
                              noWrap={prop !== "formattedStockPerSize"}
                              variant="caption"
                              customStyle={{ display: "block" }}
                            >
                              {productUtils.formatProductProperties(
                                product[prop],
                                prop,
                                locale,
                                currency
                              )}
                            </Text>
                          )}
                        </Box>
                      </Tooltip>
                    </div>
                  );
              })}
          </Stack>
        </Layer2Box>
      </Stack>
    </Box>
  );
};

export default ProductCard;
