import { formatUtils } from "@/utils";
import { COLUMN_DATA_TYPE_ENUM } from "./enums";
import { GridRowParams } from "@mui/x-data-grid";

export const formatColumn = (
  params: GridRowParams,
  value: number,
  t: (text: string) => void,
  columnDataType: COLUMN_DATA_TYPE_ENUM,
  locale: string = "fr",
  currency: string = "€"
) => {
  if (params.row.segment === t("delta_percent")) {
    return formatUtils.formatToPercentWithSign(value);
  }
  if (params.row.segment === t("delta")) {
    switch (columnDataType) {
      case COLUMN_DATA_TYPE_ENUM.PERCENT:
        return formatUtils.formatToPercentWithSign(value);
      case COLUMN_DATA_TYPE_ENUM.CURRENCY:
        return formatUtils.formatCurrencyWithSign(value, locale, currency);
      default:
        return formatUtils.formatIntWithSign(value, locale);
    }
  }
  switch (columnDataType) {
    case COLUMN_DATA_TYPE_ENUM.PERCENT:
      return formatUtils.formatToPercent(value);
    case COLUMN_DATA_TYPE_ENUM.CURRENCY:
      return formatUtils.formatCurrency(value, locale, currency);
    default:
      return formatUtils.formatInt(value, locale);
  }
};
