import { useSelector } from "@xstate/react";
import { useCallback, useMemo } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useReport } from "../../hooks/useReport";
import { Text, Stack, Box, Layer1Box, Layer2Box } from "@includes";
import { arrayUtils, formatUtils } from "@utils";
import { useViewport } from "@hooks";
import { useTranslation } from "react-i18next";
import { IKeyIndicators } from "./types";
import { getGridColumns } from "./columns";
import { useLocale } from "@/context/LocaleProvider";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "keyIndicators",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}

const allUsersIndicatorsSelector = (state: any) =>
  state.context.allUsersIndicators;

const usersIndicatorsSelector = (state: any) => state.context.usersIndicators;

const loadingSelector = (state: any) => state.context.loading;
const errorSelector = (state: any) => state.context.error;

const KeyIndicators: React.FC<IKeyIndicators> = ({
  experienceName,
  currency,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { screenHeight, screenWidth, isMobile } = useViewport();
  const reportServices = useReport();
  const { locale } = useLocale();
  const loading = useSelector(reportServices.reportService, loadingSelector);

  const error = useSelector(reportServices.reportService, errorSelector);

  const allUsersIndicators = useSelector(
    reportServices.reportService,
    allUsersIndicatorsSelector
  );

  const usersIndicators = useSelector(
    reportServices.reportService,
    usersIndicatorsSelector
  );

  const rows = useMemo(() => {
    if (usersIndicators && allUsersIndicators && experienceName) {
      return [
        {
          id: t("all_site_visitors"),
          title: t("all_site_visitors"),
          index: 0,
          ...allUsersIndicators,
        },
        {
          id: t("visitors_exposed_to"),
          title: `${t("visitors_exposed_to")} ${experienceName}	`,
          index: 1,
          ...usersIndicators.seenBuyers,
          users: usersIndicators?.seen?.users,
          percent: usersIndicators?.seen?.users / allUsersIndicators.users,
        },
        {
          id: t("visitors_used"),
          title: `${t("visitors_used")} ${experienceName}	`,
          index: 2,
          ...usersIndicators.usedBuyers,
          users: usersIndicators?.used?.users,
          percent: usersIndicators?.used?.users / usersIndicators?.seen?.users,
          revenueRatio:
            usersIndicators?.used?.transactionRevenue /
            allUsersIndicators?.transactionRevenue,
          usedRevenueRatio:
            usersIndicators?.usedBuyers?.transactionRevenue /
            usersIndicators?.used?.users /
            (usersIndicators?.seenBuyers?.transactionRevenue /
              usersIndicators?.seen?.users),
          transactionRatio:
            usersIndicators?.used.transactions /
            allUsersIndicators?.transactions,
          transactionRateMultiplyBy:
            usersIndicators?.usedBuyers?.transactionPerUser /
            usersIndicators?.seenBuyers?.transactionPerUser,

          averageBasketIncreasedBy:
            usersIndicators?.usedBuyers?.averageBasket -
            usersIndicators?.seenBuyers?.averageBasket,
          averageBasketIncreasedRate:
            !!usersIndicators?.usedBuyers?.averageBasket &&
            !!usersIndicators?.seenBuyers?.averageBasket
              ? (usersIndicators?.usedBuyers?.averageBasket -
                  usersIndicators?.seenBuyers?.averageBasket) /
                usersIndicators?.seenBuyers?.averageBasket
              : "-",
        },
      ];
    }
    return [];
  }, [usersIndicators, allUsersIndicators, experienceName]);

  const columns = useCallback(
    () => getGridColumns(theme, t, isMobile, locale, currency),
    [theme, screenWidth, screenHeight, locale]
  )();

  return (
    <Layer1Box sx={{ maxWidth: "100%" }}>
      <Stack spacing={2}>
        <Text variant="subtitle1">{t("key_indicators_over_the_period")}</Text>
        <Layer2Box
          sx={{
            width: "99%",
            boxSizing: "border-box",
            border: "1px solid",
            borderColor: theme.customPalette.others.background,
            "& .MuiDataGrid-root": {
              border: "none !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "hidden",
            },
          }}
        >
          <DataGridPro
            components={{
              Toolbar: CustomToolbar,
            }}
            autoHeight
            rowHeight={90}
            hideFooter
            loading={
              loading ||
              !experienceName ||
              (!usersIndicators && !error) ||
              (!allUsersIndicators && !error)
            }
            rowReordering={false}
            rows={rows}
            sx={{
              "& .MuiDataGrid-cellContent": {
                color: theme.customPalette.default,
              },
              borderRadius: 2,
              overflow: "hidden",
              fontFamily: "Poppins",
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },

              "& .MuiDataGrid-cell--withRenderer": {
                padding: "0 !important",
              },

              "& .MuiDataGrid-virtualScroller": {
                overflow: "auto !important",
              },
            }}
            isRowSelectable={() => false}
            columns={columns}
          />
        </Layer2Box>
      </Stack>
    </Layer1Box>
  );
};

export default KeyIndicators;
