import { gql } from "@apollo/client";

export const GET_ACCOUNT_AND_SITE = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      id
      name
      company {
        id
        name
      }
    }
  }
`;
