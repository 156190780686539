import {
  useGraphQlLazyQuery,
  useRunCollection,
  useSetState,
  useThrottle,
  useViewport,
} from "@hooks";
import {
  HideShowBlock,
  ProductList,
  SideModal,
  Text,
  Tooltip,
} from "@includes";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Stack, useTheme } from "@mui/material";
import { RootState } from "@store";
import { productUtils, rulesUtils } from "@utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ParamSelector from "./components/ParamSelector";
import { VISIBLE_FIELDS } from "./enums";
import { GET_CATALOG } from "./requests";
import { IRun } from "./types";
import { useLocale } from "@/context/LocaleProvider";

const Run: React.FC<IRun> = ({ settings, isSlider, isCollection }) => {
  const { t }: i18translateType = useTranslation();

  const requiredParameter = settings?.required_parameter;

  const { isMobile } = useViewport();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { locale } = useLocale();

  const [isOpen, setIsOpen] = useState(false);

  const { handleRun, runningCollection, collectionResult } =
    useRunCollection(siteId);

  const theme = useTheme();

  const [param, setParam] = useSetState({});
  const [categoryId, setCategoryId] = useState("");

  const { getDatas, loading, data, error } = useGraphQlLazyQuery(
    GET_CATALOG,
    { variables: { id: siteId, first: 5, cursor: "" } },
    "cache-first"
  );

  useEffect(() => {
    if (siteId) getDatas({ variables: { id: siteId, first: 5, cursor: "" } });
  }, [siteId]);

  useEffect(() => {
    if (data && Object.keys(param)?.length === 0) {
      setParam(
        productUtils.selectProductInfos(
          data?.site?.catalogs?.edges?.[0]?.node?.products?.edges?.map(
            (item: any) => item?.node
          )?.[0],
          locale
        )
      );
    }
  }, [data]);

  const [additionnalParam, setAdditionnalParam] = useSetState({});

  const additionnalParamDebounced = useThrottle(additionnalParam);
  const debouncedSettings = useThrottle(settings);

  useEffect(() => {
    if (settings && Object.keys(settings)?.length > 1) {
      handleRun(
        [param?.id],
        rulesUtils.formatSettings(settings),
        categoryId,
        param?.price,
        additionnalParamDebounced
      );
    }
  }, [param, categoryId, debouncedSettings, additionnalParamDebounced]);

  const addProduct = (product: any) => {
    setParam(productUtils.selectProductInfos(product, locale));
    setIsOpen(false);
  };

  const hasTemporaryFilter =
    settings?.settings?.filters?.find((f: Dic<any>) =>
      JSON.stringify(f).includes("strptime")
    ) ?? false;

  return (
    <HideShowBlock
      initialState
      title={t("result_example")}
      slot={
        <Box>
          <ParamSelector
            requiredParameter={requiredParameter}
            param={param}
            setIsOpen={setIsOpen}
            setCategoryId={setCategoryId}
            setAdditionnalParam={setAdditionnalParam}
            additionnalParam={additionnalParam}
          />
        </Box>
      }
      titleTooltip={
        isCollection &&
        hasTemporaryFilter && (
          <Tooltip title={t("warning_date_preview")}>
            <div>
              <WarningAmberIcon
                sx={{ fill: theme.customPalette.warning.main }}
              />
            </div>
          </Tooltip>
        )
      }
    >
      <Box id="preview-block">
        <SideModal
          position="right"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          child="search_product"
          params={{
            selectedProducts: [param],
            addProduct,
            type: "set parameter",
          }}
        />
        <Stack spacing={2}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            {rulesUtils.getExecutionParameter(requiredParameter) && (
              <Text textType="secondary" variant="body2">
                {t("preview_description_1")} <br />
                {t("preview_description_2")}
              </Text>
            )}

            <ProductList
              list="run result"
              visibleFields={VISIBLE_FIELDS}
              products={collectionResult ?? []}
              isLoading={runningCollection || loading}
              isSlider={isSlider}
            />
          </Stack>
        </Stack>
      </Box>
    </HideShowBlock>
  );
};

Run.defaultProps = {
  isCollection: false,
};

export default Run;
