import {
  CHANGE_SITE,
  CREATE_SITE,
  GENERATE_RESOURCES,
  RESOURCES_GENERATED,
  SET_CRON,
  SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
  SET_DEFAULT_CMS_INTEGRATION_ID,
  SET_DEFAULT_EMAILING_INTEGRATION_ID,
  SET_HAS_AB_TEST,
  SET_HAS_BUILDER,
  SET_HAS_EMOTION_AI,
  SET_HAS_SKU_REPORT,
  SET_HIDE_CATALOG,
  SET_HIDE_MERCH,
  SET_HIDE_REPORT,
} from "./action-types";

export function generateResources() {
  return {
    type: GENERATE_RESOURCES,
  };
}

export function resourcesGenerated() {
  return {
    type: RESOURCES_GENERATED,
  };
}

export function setDefaultEmailingIntegrationId(
  defaultEmailingIntegrationId: ID
): any {
  return {
    type: SET_DEFAULT_EMAILING_INTEGRATION_ID,
    payload: {
      defaultEmailingIntegrationId,
    },
  };
}

export function setDefaultCmsIntegrationId(defaultCmsIntegrationId: ID): any {
  return {
    type: SET_DEFAULT_CMS_INTEGRATION_ID,
    payload: {
      defaultCmsIntegrationId,
    },
  };
}

export function setDefaultAnalyticsIntegrationId(
  defaultAnalyticsIntegrationId: ID
): any {
  return {
    type: SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
    payload: {
      defaultAnalyticsIntegrationId,
    },
  };
}

export function userCreateSite(siteId: ID, siteName: string): any {
  return {
    type: CREATE_SITE,
    payload: {
      siteId,
      siteName,
    },
  };
}

export function userChangeSite(
  siteId: string,
  siteName: string,
  accountId: string,
  accountName: string
): any {
  return {
    type: CHANGE_SITE,
    payload: {
      siteId,
      siteName,
      accountId,
      accountName,
    },
  };
}

export function setHideCatalog(hideCatalog: boolean) {
  return {
    type: SET_HIDE_CATALOG,
    payload: {
      hideCatalog,
    },
  };
}

export function setHasAbTest(hasAbtest: boolean) {
  return {
    type: SET_HAS_AB_TEST,
    payload: {
      hasAbtest,
    },
  };
}

export function setHasSkuReport(hasSkuReport: boolean) {
  return {
    type: SET_HAS_SKU_REPORT,
    payload: {
      hasSkuReport,
    },
  };
}

export function setHasBuilder(hasBuilder: boolean) {
  return {
    type: SET_HAS_BUILDER,
    payload: {
      hasBuilder,
    },
  };
}

export function setHideMerch(hideMerch: boolean) {
  return {
    type: SET_HIDE_MERCH,
    payload: {
      hideMerch,
    },
  };
}

export function setHideReport(hideReport: boolean) {
  return {
    type: SET_HIDE_REPORT,
    payload: {
      hideReport,
    },
  };
}

export function setHasEmotionAi(hasEmotionAi: boolean) {
  return {
    type: SET_HAS_EMOTION_AI,
    payload: {
      hasEmotionAi,
    },
  };
}

export function setCron(cron: string) {
  return {
    type: SET_CRON,
    payload: {
      cron,
    },
  };
}
