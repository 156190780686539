import GridToolbar from "@/components/_Molecules/GridToolbar";
import { usePersistedState } from "@/hooks";
import { DataGrid } from "@/includes";
import { RootState } from "@/services/redux/store";
import { useTheme } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loading from "../KeyIndicatorsLoading";
import getColumns from "./columns";
import { getAdditionalColumns, getInitialColumns } from "./helpers";
import { TableProps } from "./types";
import { useLocale } from "@/context/LocaleProvider";

const TableKeyIndicators = ({ rows, experienceId, apiRef }: TableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [datagridColumns, setDatagridColumns] = useState<Array<any>>([]);
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { locale } = useLocale();
  const columns = getColumns(t, locale, currency);
  const [columnVisibilityModel, setColumnVisibilityModel] = usePersistedState({
    key: `AnalyticsTableVisibilityModel-keyIndicators-${siteId}-${experienceId}`,
    initialValue: {},
  });
  const [initialData, setInitialData] = useState<Dic<any>>({});

  useEffect(() => {
    if (!isEmpty(columns)) {
      setDatagridColumns([
        ...columns,
        ...getAdditionalColumns(rows, t, locale),
      ]);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(datagridColumns)) {
      setInitialData(getInitialColumns(datagridColumns));
    }
  }, [datagridColumns]);

  useEffect(() => {
    if (
      isEmpty(columnVisibilityModel) &&
      !isEmpty(initialData) &&
      !isNil(experienceId)
    ) {
      setColumnVisibilityModel(initialData);
    } else if (!isEmpty(initialData) && !isNil(experienceId)) {
      setColumnVisibilityModel({ ...initialData, ...columnVisibilityModel });
    }
  }, [initialData]);

  if (isEmpty(columnVisibilityModel)) return <Loading />;
  return (
    <DataGrid
      apiRef={apiRef}
      paginationType="server"
      hideFooter
      sortingMode="server"
      columns={datagridColumns}
      rows={rows}
      componentsProps={{
        panel: {
          sx: {
            "& .MuiDataGrid-paper": {
              backgroundColor: "unset",
              background: theme.customPalette.others.layer_1,
            },
          },
        },
      }}
      components={{
        Toolbar: GridToolbar,
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) => {
        if (isEmpty(model)) {
          setColumnVisibilityModel(initialData);
        } else {
          setColumnVisibilityModel(model);
        }
      }}
      currentPage={0}
      pageSize={3}
    />
  );
};

export default TableKeyIndicators;
