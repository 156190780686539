import { Text } from "@/includes";
import { formatUtils } from "@/utils";
import { Box, Stack } from "@includes";
import { GridRowParams } from "@mui/x-data-grid";
import { arrayUtils } from "@utils";

export function getGridColumns(
  theme: Dic<any>,
  t: (text: string) => string,
  isMobile: boolean,
  locale = "fr",
  currency: string = "€"
): any {
  return [
    {
      field: "title",
      headerName: t("title"),
      sortable: false,
      align: "left",
      width: 180,
      disableColumnMenu: true,
      renderHeader: () => <></>,
      renderCell: (params: GridRowParams) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",

            display: "flex",
            alignItems: "center",
          }}
        >
          <Text customStyle={{ width: "100%" }}>
            {arrayUtils
              .divideListIntoSubList(params.row.title.split(" "), 3)
              .map((item: any) => item.join(" "))
              .map((res: string) => (
                <Text noWrap customStyle={{ maxWidth: "80%" }} key={res}>
                  {res}
                </Text>
              ))}
          </Text>
        </Box>
      ),
    },
    {
      field: "users",
      headerName: t("users"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="users"
            src={`/${theme.customPalette.mode}/report/keyIndic/users.svg`}
          />

          <Text noWrap variant="body2" centerText textType="secondary">
            {t("users")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          alignItems="start"
          spacing={0}
          justifyContent="center"
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatInt(params.row.users, locale)}
          </Text>
          {params.row.index === 2 ? (
            <Text
              variant="body1"
              customStyle={{
                color: theme.customPalette.bluePurple.dark,
              }}
            >
              ({formatUtils.formatToPercent(params.row.percent)})
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "transactionRevenue",
      headerName: t("revenue"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="Revenue"
            src={`/${theme.customPalette.mode}/report/keyIndic/paid.svg`}
          />

          <Text noWrap variant="body2" centerText textType="secondary">
            {t("revenue")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          alignItems="start"
          spacing={0}
          justifyContent="center"
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatIntegerCurrency(
              params.row.transactionRevenue,
              locale,
              currency
            )}
          </Text>
          {params.row.index === 2 ? (
            <Text
              variant="body1"
              customStyle={{
                color: theme.customPalette.citrus.light,
              }}
            >
              ({formatUtils.formatToPercent(params.row.revenueRatio)})
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "revenuePerUser",
      headerName: t("revenue_per_user"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="Revenue"
            src={`/${theme.customPalette.mode}/report/keyIndic/revenuePerUser.svg`}
          />

          <Text noWrap variant="body2" centerText textType="secondary">
            {t("revenue_per_user")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          alignItems="start"
          spacing={0}
          justifyContent="center"
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatCurrency(
              params.row.revenuePerUser,
              locale,
              currency
            )}
          </Text>
          {params.row.index === 2 ? (
            <Text
              variant="body1"
              customStyle={{
                color: theme.customPalette.bluePurple.light,
              }}
            >
              ({formatUtils.formatMultiply(params.row.usedRevenueRatio)})
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "transactions",
      headerName: t("transactions"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="Transactions"
            src={`/${theme.customPalette.mode}/report/keyIndic/transactions.svg`}
          />

          <Text noWrap variant="body2" centerText textType="secondary">
            {t("transactions")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          justifyContent="center"
          alignItems="start"
          spacing={0}
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatInt(params.row.transactions, locale)}
          </Text>
          {params.row.index === 2 ? (
            <Text
              textType="secondary"
              variant="body1"
              customStyle={{
                fontFamily: "Poppins",
              }}
            >
              ({formatUtils.formatToPercent(params.row.transactionRatio)})
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "conversionRate",
      headerName: t("transactions_rate"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="Transactions rate"
            src={`/${theme.customPalette.mode}/report/keyIndic/rate.svg`}
          />
          <Text noWrap variant="body2" centerText textType="secondary">
            {t("transactions_rate")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          justifyContent="center"
          alignItems="start"
          spacing={0}
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatToPercent(params.row.conversionRate)}
          </Text>
          {params.row.index === 2 ? (
            <Text
              textType="secondary"
              variant="body1"
              customStyle={{
                fontFamily: "Poppins",
              }}
            >
              (
              {formatUtils.formatMultiply(params.row.transactionRateMultiplyBy)}
              )
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
    {
      field: "averageBasket",
      headerName: t("average_cart"),
      sortable: false,
      align: "left",
      flex: isMobile ? "unset" : 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="img"
            alt="Average cart"
            src={`/${theme.customPalette.mode}/report/keyIndic/averageBasket.svg`}
          />
          <Text noWrap variant="body2" centerText textType="secondary">
            {t("average_cart")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          justifyContent="center"
          alignItems="start"
          spacing={0}
          sx={{
            background: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            noWrap
            variant="body1"
            centerText
            customStyle={{ padding: "0 5px", fontWeight: 700 }}
          >
            {formatUtils.formatCurrency(
              params.row.averageBasket,
              locale,
              currency
            )}
          </Text>
          {params.row.index === 2 ? (
            <Text
              textType="secondary"
              variant="body1"
              customStyle={{
                fontFamily: "Poppins",
              }}
            >
              (
              {formatUtils.formatToPercentWithSymbol(
                params.row.averageBasketIncreasedRate
              )}
              )
            </Text>
          ) : (
            <></>
          )}
        </Stack>
      ),
    },
  ];
}
