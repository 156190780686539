import {
  AutoComplete,
  CatalogColumnData,
  SelectPaper,
  StyledTextField,
  Text,
} from "@/includes";
import { RootState } from "@/services/redux/store";
import { comparisonUtils, filterUtils, rulesUtils } from "@/utils";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OutputVariableSelector from "../OutputVariabelSelector";
import { ManageFilter } from "./ManageFilter";
import { IFilter } from "./types";
import { VARIABLE_TYPE_ENUM } from "@/hooks/useGetTypes/enums";
import { getTraduction } from "@/utils/helpers/traductions";

const Filter: React.FC<IFilter> = ({
  filter,
  handleChange,
  variables,
  requiredParameters,
  remove,
  filterType,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const manageFilter = new ManageFilter(filterType);
  const { entry, output, operator } = filter;

  const isDynamic =
    filterType === "exception" ? false : output.type !== "constant";

  const isProductIds =
    requiredParameters?.[output.value] === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
    requiredParameters?.[output.value] === VARIABLE_TYPE_ENUM.ITEM_ID; // -> most frequent key filter type

  const allOperations = rulesUtils.getOperators(t);

  const productFields = useSelector((state: RootState) =>
    state.catalog.catalogColumns?.filter((c: Dic<string>) => c?.type)
  );

  const entryParamType = productFields.find(
    (pf: Dic<string>) => pf.name === entry.key
  )?.type;

  const outputParamType = allOperations
    .find((_operator: Dic<any>) => _operator.name === operator.name)
    ?.types?.find((type: Dic<any>) => type.entry === entryParamType)?.output;

  const availableOperators = allOperations.filter(
    (o: Dic<any>) =>
      o.types.filter(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "entry",
          entryParamType
        )
      )?.length > 0
  );

  const getNewOutput = (newParamType: string): Dic<any> => {
    return {
      value: filterUtils.getDefaultValue(newParamType),
      type: "constant",
      key: "",
    };
  };

  const handleChangeEntryProperty = (newEntryProperty: string) => {
    const newParamType = productFields.find(
      (pf: Dic<string>) => pf.name === newEntryProperty
    )?.type;
    const newInterpretation = {
      ...filter,
      operator: { name: filterUtils.getDefaultOperation(newParamType) },
      entry: {
        ...filter.entry,
        key: newEntryProperty,
      },
      output: {
        ...getNewOutput(newParamType),
      },
    };
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule(newInterpretation)
    );
  };

  const handleChangeOperation = (newOperation: string) => {
    const newOutputParamType = allOperations
      .find((_operator: Dic<any>) => _operator.name === newOperation)
      ?.types?.find((type: Dic<any>) => type.entry === entryParamType)?.output;
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        operator: { name: newOperation },
        output: getNewOutput(newOutputParamType),
      })
    );
  };

  const handleChangeDynamicStaticState = () => {
    if (isDynamic) {
      handleChange(
        manageFilter.handleTransformInterpretationIntoRule({
          ...filter,
          output: {
            type: "constant",
            value: filterUtils.getDefaultValue(outputParamType),
            key: "",
          },
        })
      );
    } else {
      handleChange(
        manageFilter.handleTransformInterpretationIntoRule({
          ...filter,
          output: {
            type: "most_frequent_key",
            value: "",
            key: entry.key,
          },
        })
      );
    }
  };

  const handleChangeOutputKey = (key: string) => {
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        output: {
          ...filter.output,
          key,
        },
      })
    );
  };

  const getOutputOnVariableTypeChange = (value: string) => {
    const newOutputVariableType = requiredParameters[value];
    if (!isDynamic)
      return {
        ...filter.output,
        type: "constant",
        value,
        key: "",
      };

    if (
      newOutputVariableType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
      newOutputVariableType === VARIABLE_TYPE_ENUM.ITEM_ID
    ) {
      // most_frequent_key
      return {
        ...filter.output,
        type: "most_frequent_key",
        value,
        key: filter.entry.key,
      };
    }
    // params
    return {
      ...filter.output,
      type: "params",
      value,
      key: "",
    };
  };

  const handleChangeOutputValue = (value: string) => {
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        output: {
          ...getOutputOnVariableTypeChange(value),
        },
      })
    );
  };

  const getEntry = () => {
    if (filter.entry.type === "params") {
      return variables.find((variable: string) => variable === entry.value);
    } else {
      return productFields.find((p: Dic<string>) => p.name === entry.key);
    }
  };

  return (
    <Stack direction="row" alignItems="end" spacing={1}>
      <AutoComplete
        size="small"
        onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
          const { name, type } = value;
          handleChangeEntryProperty(name);
        }}
        value={getEntry()}
        options={productFields ?? []}
        disableClearable
        sx={{ width: 180 }}
        getOptionLabel={(option: Dic<string>) => getTraduction(t, option.name)}
        PaperComponent={SelectPaper}
        renderInput={(params: any) => (
          <StyledTextField {...params} label={t("field")} name={t("field")} />
        )}
      />
      <AutoComplete
        size="small"
        value={allOperations.find((o: Dic<any>) => o.name === operator.name)}
        onChange={(e: React.SyntheticEvent, value: any) =>
          handleChangeOperation(value.name)
        }
        sx={{ width: 200, minWidth: 170 }}
        disableClearable
        getOptionLabel={(option: Dic<any>) => option.description}
        options={availableOperators}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label={t("operator")}
            name={t("operator")}
          />
        )}
        PaperComponent={SelectPaper}
      />
      <Stack alignItems="start">
        {filterType === "filter" && (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isDynamic}
                onChange={handleChangeDynamicStaticState}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<Text>{t("dynamic")}</Text>}
          />
        )}
        {!isDynamic && (
          <CatalogColumnData
            outputParamType={outputParamType}
            handleChangeValue={handleChangeOutputValue}
            value={output.value}
            columnName={
              productFields.find((p: Dic<string>) => p.name === entry.key)?.name
            }
          />
        )}
        {isDynamic && isProductIds && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <AutoComplete
              size="small"
              onChange={(
                e: React.SyntheticEvent<Element, Event>,
                value: any
              ) => {
                const { name, type } = value;
                handleChangeOutputKey(name);
              }}
              value={productFields.find(
                (p: Dic<string>) => p.name === output.key
              )}
              options={productFields ?? []}
              disableClearable
              sx={{ width: 180 }}
              getOptionLabel={(option: Dic<string>) =>
                getTraduction(t, option.name)
              }
              PaperComponent={SelectPaper}
              renderInput={(params: any) => (
                <StyledTextField
                  {...params}
                  label={getTraduction(t, "field")}
                  name={getTraduction(t, "field")}
                />
              )}
            />
            <OutputVariableSelector
              variables={variables}
              handleChangeOutputKey={handleChangeOutputValue}
              outputKey={output.value}
            />
          </Stack>
        )}
        {isDynamic && !isProductIds && (
          <OutputVariableSelector
            variables={variables}
            handleChangeOutputKey={handleChangeOutputValue}
            outputKey={output.value}
          />
        )}
      </Stack>
      <IconButton onClick={remove}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

Filter.defaultProps = {};

export default Filter;
