import { formatUtils } from "@/utils";
import { AB_TEST_EVOLUTION_METRICS_ENUM, yAxisUnit } from "../../enums";

export const formatDataFromEvolutionMetric = (
  value: number,
  evolutionMetric: AB_TEST_EVOLUTION_METRICS_ENUM,
  locale: string = "fr",
  currency: string = "€"
) => {
  const type = yAxisUnit[evolutionMetric];
  if (type === "currency") {
    return formatUtils.formatCurrency(value, locale, currency);
  }
  if (type === "float") {
    return formatUtils.formatFloat(value, locale);
  }
  return formatUtils.formatInt(value, locale);
};
