import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { PayloadAction } from "@reduxjs/toolkit";
import produce, { Draft } from "immer";
import {
  CHANGE_SITE,
  CREATE_SITE,
  GENERATE_RESOURCES,
  LOGGED_IN,
  LOGOUT,
  RESOURCES_GENERATED,
  SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
  SET_DEFAULT_CMS_INTEGRATION_ID,
  SET_DEFAULT_EMAILING_INTEGRATION_ID,
  SET_HAS_AB_TEST,
  SET_HAS_BUILDER,
  SET_HIDE_CATALOG,
  SET_HIDE_MERCH,
  SET_HIDE_REPORT,
  SET_HAS_SKU_REPORT,
  SET_HAS_EMOTION_AI,
  SET_CRON,
} from "../actions/action-types";

export const initialState = {
  siteId: potionsLocalStorage.siteId,
  siteName: potionsLocalStorage.siteName ?? "",
  hasAbtest: false,
  hasSkuReport: false,
  hideCatalog: false,
  hasBuilder: false,
  defaultEmailingIntegrationId: null,
  defaultAnalyticsIntegrationId: null,
  defaultCmsIntegrationId: null,
  shouldGenerateResources: false,
  hideMerch: false,
  hideReport: false,
  hasEmotionAi: false,
  cron: "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case GENERATE_RESOURCES:
        draft.shouldGenerateResources = true;
        break;
      case RESOURCES_GENERATED:
        draft.shouldGenerateResources = false;
        break;
      case SET_DEFAULT_ANALYTICS_INTEGRATION_ID:
        draft.defaultAnalyticsIntegrationId =
          action.payload.defaultAnalyticsIntegrationId;
        break;
      case SET_DEFAULT_CMS_INTEGRATION_ID:
        draft.defaultCmsIntegrationId = action.payload.defaultCmsIntegrationId;
        break;
      case SET_DEFAULT_EMAILING_INTEGRATION_ID:
        draft.defaultEmailingIntegrationId =
          action.payload.defaultEmailingIntegrationId;
        break;
      case CHANGE_SITE:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.cron = "";
        break;
      case CREATE_SITE:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.cron = "";
        break;
      case LOGGED_IN:
        draft.siteId = action.payload.siteId;
        draft.siteName = action.payload.siteName;
        draft.shouldGenerateResources = action.payload.shouldGenerateResources;
        draft.cron = action.payload.cron;
        break;
      case LOGOUT:
        draft.siteId = null;
        draft.siteName = "";
        draft.defaultEmailingIntegrationId = null;
        draft.shouldGenerateResources = false;
        draft.cron = "";
        break;
      case SET_HAS_AB_TEST:
        draft.hasAbtest = action.payload.hasAbtest;
        break;
      case SET_HIDE_CATALOG:
        draft.hideCatalog = action.payload.hideCatalog;
        break;
      case SET_HAS_SKU_REPORT:
        draft.hasSkuReport = action.payload.hasSkuReport;
        break;
      case SET_HAS_BUILDER:
        draft.hasBuilder = action.payload.hasBuilder;
        break;
      case SET_HIDE_MERCH:
        draft.hideMerch = action.payload.hideMerch;
        break;
      case SET_HIDE_REPORT:
        draft.hideReport = action.payload.hideReport;
        break;
      case SET_HAS_EMOTION_AI:
        draft.hasEmotionAi = action.payload.hasEmotionAi;
        break;
      case SET_CRON:
        draft.cron = action.payload.cron;
        break;
      default:
        break;
    }
  });
}
