import { gql } from "@apollo/client";

export const SEARCH_ACCOUNT = gql`
  query companies($search: String, $first: Int!) {
    companies(first: $first) {
      edges {
        node {
          id
          name
          sites(search: $search) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
