import { Text } from "@/includes";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { COMMITMENT_HEADERS } from "@/utils/enums/graphql";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loading from "../EngagementMeasureLoading";
import EngagementMeasureVisualization from "../EngagementMeasureVisualization";
import { GET_ENGAGEMENT_MEASURE_DATA } from "./requests";
import { EngagementMeasureDataType, IEngagementMeasure } from "./types";
import { useLocale } from "@/context/LocaleProvider";

const EngagementMeasure: React.FC<IEngagementMeasure> = ({
  filters,
  filtersCompare,
  compare,
}) => {
  const { t }: i18translateType = useTranslation();
  const {
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    experience,
    devices,
  } = filters;
  const {
    startDate: startDateCompare,
    endDate: endDateCompare,
    previousStartDate: previousStartDateCompare,
    previousEndDate: previousEndDateCompare,
    experience: experienceCompare,
    devices: devicesCompare,
  } = filtersCompare;
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { locale } = useLocale();
  const [status, setStatus] = useState<StatusType>(StatusEnum.LOADING);
  const [statusCompare, setStatusCompare] = useState<StatusType>(
    StatusEnum.NORMAL
  );
  const [data, setData] = useState<EngagementMeasureDataType | null>(null);
  const [dataCompare, setDataCompare] =
    useState<EngagementMeasureDataType | null>(null);

  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_ENGAGEMENT_MEASURE_DATA,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          previousStartDate: dayjs(previousStartDate).format("YYYY-MM-DD"),
          previousEndDate: dayjs(previousEndDate).format("YYYY-MM-DD"),
          segment: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };all`,
          devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: Dic<any>) => {
        setData(res?.data);
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  const getDataCompare = () => {
    setStatusCompare(StatusEnum.LOADING);
    client
      .query({
        query: GET_ENGAGEMENT_MEASURE_DATA,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDateCompare).format("YYYY-MM-DD"),
          endDate: dayjs(endDateCompare).add(1, "day").format("YYYY-MM-DD"),
          previousStartDate: dayjs(previousStartDateCompare).format(
            "YYYY-MM-DD"
          ),
          previousEndDate: dayjs(previousEndDateCompare)
            .add(1, "day")
            .format("YYYY-MM-DD"),
          segment: `potions;${experienceCompare.type?.toLowerCase()};${
            experienceCompare.analyticsName
          };all`,
          devices: devicesCompare,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: Dic<any>) => {
        setDataCompare(res?.data);
        setStatusCompare(StatusEnum.NORMAL);
      })
      .catch(() => setStatusCompare(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices) &&
      !isEmpty(experience)
    ) {
      getData();
    }
  }, [startDate, endDate, devices, experience]);

  useEffect(() => {
    if (
      compare &&
      !isEmpty(startDateCompare) &&
      !isEmpty(endDateCompare) &&
      !isEmpty(devicesCompare) &&
      !isEmpty(experienceCompare)
    )
      getDataCompare();
  }, [startDateCompare, endDateCompare, devicesCompare, experienceCompare]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Text variant="h2">{t("engagement_title")}</Text>
        <Tooltip
          title={`${t("data_period_tooltip")} ${startDate} ${t(
            "to"
          )} ${endDate}`}
        >
          <HelpOutlineIcon sx={{ fill: "#BEBBC9" }} />
        </Tooltip>
      </Stack>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {status !== StatusEnum.LOADING &&
            statusCompare !== StatusEnum.LOADING &&
            data?.currentPeriod?.headers?.map(
              (header: COMMITMENT_HEADERS, index: number) => {
                const value = data.currentPeriod?.rows[0][index]?.floatValue;
                if (!isNil(value) && value !== 0) {
                  return (
                    <EngagementMeasureVisualization
                      key={header}
                      header={header}
                      newValue={value}
                      oldValue={data.previousPeriod?.rows[0][index]?.floatValue}
                      compare={compare}
                      compareValue={
                        dataCompare?.currentPeriod?.rows[0][index]
                          ?.floatValue ?? null
                      }
                      status={status}
                      statusCompare={statusCompare}
                      locale={locale}
                    />
                  );
                }
                return;
              }
            )}
          {status === StatusEnum.LOADING ||
            (statusCompare === StatusEnum.LOADING && <Loading />)}
        </Grid>
      </Box>
    </Stack>
  );
};

export default EngagementMeasure;
