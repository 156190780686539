import { IIcon } from "./types";

const TemplatesIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.63379 14.5938V5.09375C6.63379 4.26532 7.30536 3.59375 8.13379 3.59375H17.1338C17.9622 3.59375 18.6338 4.26532 18.6338 5.09375V6.59375"
      stroke={color}
      strokeWidth="1.75"
    />
    <path
      d="M18.6338 11.5937L18.6338 20.0938C18.6338 20.9222 17.9622 21.5937 17.1338 21.5937L8.13379 21.5937C7.30536 21.5937 6.63379 20.9222 6.63379 20.0937L6.63379 18.5937"
      stroke={color}
      strokeWidth="1.75"
    />
    <rect
      x="10.5088"
      y="6.46875"
      width="11.25"
      height="5.25"
      rx="1.625"
      stroke={color}
      strokeWidth="1.75"
    />
    <rect
      x="3.50879"
      y="14.4688"
      width="8.25"
      height="4.25"
      rx="1.625"
      stroke={color}
      strokeWidth="1.75"
    />
  </svg>
);

export default TemplatesIcon;
