import { productUtils } from "@utils";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { client } from "../../index";
import useSentry from "../useSentry";
import useSetState from "../useSetState";
import { formatValue } from "./formatters";
import { RUN_COLLECTION, RUN_PAGE } from "./requests";
import { useLocale } from "@/context/LocaleProvider";

/**
 * Custom React hook for running collections and handling results.
 *
 * @param {string} siteId - The site identifier.
 * @returns {{ handleRun: Function, runningCollection: boolean, collectionResult: Array<Dic<any>>, handleRunPage: Function, handleRunEmail: Function, newHandleRun: Function }} - An object with functions to run collections and their results.
 */
const useRunCollection = (siteId: string) => {
  // Sentry error tracking hooks
  const { captureObject } = useSentry();
  const abortControllerRef = useRef<AbortController | null>(null); // Référence pour le contrôleur d'annulation
  const { locale } = useLocale();
  const [run, setRun] = useSetState({
    runningCollection: true,
    collectionResult: [],
    error: "",
  });

  /**
   * Function to format settings before running a collection.
   *
   * @param {any} _settings - Collection settings.
   * @returns {Dic<any>} - Formatted collection settings.
   */
  const formatSettings = (_settings: any) => {
    let formattedSettings: Dic<any> = {};
    let settings = _settings;
    let subKeys = settings?.sub_keys;

    try {
      delete settings.settings.sub_keys;
    } catch (e: any) {}
    if (!settings) return formattedSettings;
    if (settings.composed_collections) {
      formattedSettings = {
        settings: { ...settings?.settings },
        composed_collections: settings.composed_collections.map((c: any) =>
          c?.collection ? c : { collection: c, size: -1 }
        ),
      };
    } else if (settings.algorithm) {
      formattedSettings = {
        algorithm: settings.algorithm,
        settings: { ...settings.settings },
      };
    } else {
      formattedSettings = {
        ...settings,
        settings: { ...settings.settings, fields: "all" },
      };
    }
    if (subKeys) {
      formattedSettings = { ...formattedSettings, sub_keys: subKeys };
    }
    const filters = formattedSettings.settings?.filters ?? [];
    // remove date filter to run
    const newFilters = filters.filter(
      (f: Dic<any>) => !JSON.stringify(f).includes("strptime")
    );
    if (!formattedSettings.blocks)
      formattedSettings.settings.filters = newFilters;

    return {
      ...formattedSettings,
      required_parameter: settings?.required_parameter,
    };
  };

  /**
   * Function to run page api.
   *
   * @param {string} category_id - Category ID.
   * @param {any} settings - Collection settings.
   */
  const handleRunPage = (category_id: string, settings: any) => {
    try {
      const pushedProducts = settings?.pushedProducts;
      const pushedProductsIds: Array<string> = pushedProducts
        ? Object.values(pushedProducts)
        : [];
      setRun({ runningCollection: true });
      client
        .query({
          query: RUN_PAGE,
          variables: {
            id: parseInt(siteId),
            query: {
              query: {
                parameters: {
                  category_id,
                  viewed_product_ids: [],
                  cart_product_ids: [],
                  lang: "",
                  cart_price: 50,
                },
                query: {
                  experience_settings: { fields: ["all"] },
                  page: {
                    collection: {
                      query: {
                        ...settings.collectionSettings,
                        category_id,
                      },
                    },
                    settings: {
                      pushed_products: pushedProducts,
                    },
                  },
                },
              },
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((res: any) => {
          setRun({ runningCollection: false });
          if (res?.data) {
            setRun({
              collectionResult:
                res?.data?.queryPageApi?.response?.products?.map(
                  (p: Dic<any>) => {
                    const isPinned = !!pushedProductsIds?.find(
                      (pId: string) => pId === p?.id
                    );
                    return productUtils.selectProductInfos(
                      { ...p, isPinned },
                      locale
                    );
                  }
                ),
            });
          }
        })
        .catch((error: any) => {
          setRun({ runningCollection: false, collectionResult: [] });
          captureObject("Error server - Run page : queryPageApi", {
            siteId: parseInt(siteId),
            query: JSON.stringify({
              query: {
                parameters: {
                  category_id,
                  viewed_product_ids: [],
                  cart_product_ids: [],
                  lang: "",
                  cart_price: 50,
                },
                query: {
                  experience_settings: { fields: ["all"] },
                  page: {
                    collection: {
                      query: {
                        ...settings.collectionSettings,
                        category_id,
                      },
                    },
                    settings: {
                      pushed_products: pushedProducts,
                    },
                  },
                },
              },
            }),
            errorMessage: error?.message,
          });
        });
    } catch (error: any) {
      setRun({ runningCollection: false, collectionResult: [] });
      captureObject("Error Try Catch : handleRunPage", {
        errorMessage: error?.message,
      });
    }
  };

  /**
   * Function to handle running an email collection.
   *
   * @param {Dic<any>} settings - Collection settings.
   * @param {Dic<string>} value - Values for the email.
   */
  const handleRunEmail = (
    settings: Dic<any>,
    value: Dic<string>,
    withoutFormat = false
  ) => {
    setRun({ runningCollection: true });
    const formattedValues = withoutFormat ? value : formatValue(value);
    client
      .query({
        query: RUN_COLLECTION,
        variables: {
          id: parseInt(siteId),
          query: {
            query: {
              query: {
                ...settings,
                settings: {
                  ...settings.settings,
                  fields: ["all"],
                },
              },
              parameters: {
                viewed_product_ids: [],
                cart_product_ids: [],
                lang: "",
                cart_price: 50,
                ...formattedValues,
              },
            },
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        setRun({ runningCollection: false });
        if (res?.data) {
          setRun({
            collectionResult:
              res?.data?.queryResourcesApi?.response?.products?.map(
                (p: Dic<any>) => productUtils.selectProductInfos(p, locale)
              ),
            runningCollection: false,
          });
          captureObject("Info No recommendations - Email experience", {
            siteId: parseInt(siteId),
            query: JSON.stringify({
              query: {
                query: {
                  ...settings,
                  settings: {
                    ...settings.settings,
                    fields: ["all"],
                  },
                },
                parameters: {
                  viewed_product_ids: [],
                  cart_product_ids: [],
                  lang: "",
                  cart_price: 50,
                  ...formattedValues,
                },
              },
            }),
          });
        }
      })
      .catch((error: any) => {
        if (error.name === "AbortError") {
          console.info("Requête annulée");
        } else {
          setRun({ runningCollection: false, collectionResult: [] });
          captureObject("Error Server - Run mail : queryResourcesApi", {
            siteId: parseInt(siteId),
            query: JSON.stringify({
              query: {
                query: {
                  ...settings,
                  settings: {
                    ...settings.settings,
                    fields: ["all"],
                  },
                },
                parameters: {
                  viewed_product_ids: [],
                  cart_product_ids: [],
                  lang: "",
                  cart_price: 50,
                  ...formattedValues,
                },
              },
            }),
            errorMessage: error?.message,
          });
        }
      });
  };

  /**
   * Function to handle running a collection.
   *
   * @param {Array<number>} productIds - Product IDs.
   * @param {Dic<any>} collectionSettings - Collection settings.
   * @param {string} categoryId - Category ID.
   * @param {number} cartTotal - Cart total.
   * @param {Dic<any>} params - Additional parameters.
   */
  const handleRun = (
    productIds: Array<number>,
    collectionSettings: Dic<any>,
    categoryId = "",
    cartTotal = 0,
    params = {}
  ) => {
    setRun({ runningCollection: true });
    client
      .query({
        query: RUN_COLLECTION,
        variables: {
          id: parseInt(siteId),
          query: {
            query: {
              query: {
                ...formatSettings(collectionSettings),
              },
              parameters: {
                product_ids: productIds,
                category_id: categoryId,
                product_id: productIds?.[0],
                viewed_product_ids: productIds,
                cart_product_ids: [],
                lang: "",
                cart_price: 50,
                ...params,
              },
            },
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res: any) => {
        setRun({ runningCollection: false });

        if (res?.data) {
          setRun({
            collectionResult:
              res?.data?.queryResourcesApi?.response?.products?.map(
                (p: Dic<any>) => productUtils.selectProductInfos(p, locale)
              ),
          });
          if (res?.data?.queryResourcesApi?.response?.products?.length === 0) {
            captureObject("Error no recommendations - Collection", {
              siteId: parseInt(siteId),
              query: JSON.stringify({
                query: {
                  query: {
                    ...formatSettings(collectionSettings),
                  },
                  parameters: {
                    product_ids: productIds,
                    category_id: categoryId,
                    product_id: productIds?.[0],
                    viewed_product_ids: productIds,
                    cart_product_ids: [],
                    lang: "",
                    cart_price: 50,
                    ...params,
                  },
                },
              }),
            });
          }
        }
      })
      .catch((error: Error) => {
        setRun({ runningCollection: false, collectionResult: [] });
        captureObject("Error Server - Run : queryResourcesApi", {
          siteId: parseInt(siteId),
          query: JSON.stringify({
            query: {
              query: {
                ...formatSettings(collectionSettings),
              },
              parameters: {
                product_ids: productIds,
                category_id: categoryId,
                product_id: productIds?.[0],
                viewed_product_ids: productIds,
                cart_product_ids: [],
                lang: "",
                cart_price: 50,
                ...params,
              },
            },
          }),
          errorMessage: error?.message,
        });
      });
  };

  /**
   * Function to handle running a collection with conditional rules.
   *
   * @param {Dic<any>} settings - Collection settings.
   * @param {Dic<string>} requiredParameters - Required parameters.
   * @param {Dic<Dic<any>>} params - Additional parameters.
   */
  const newHandleRun = (
    settings: Dic<any>,
    requiredParameters: Dic<string>,
    params: Dic<Dic<any>>
  ) => {
    // Si une requête précédente est en cours, on l'annule
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Annule la requête précédente
    }

    // Crée un nouveau contrôleur d'annulation pour la requête en cours
    const abortController = new AbortController();
    abortControllerRef.current = abortController; // Stocke le contrôleur actuel
    if (!!settings?.blocks && !isEmpty(settings?.blocks)) {
      setRun({ runningCollection: true });
      client
        .query({
          query: RUN_COLLECTION,
          variables: {
            id: parseInt(siteId),
            query: {
              query: {
                query: {
                  ...formatSettings(settings),
                },
                parameters: {
                  ...params,
                },
              },
            },
          },
          fetchPolicy: "no-cache",
          context: {
            fetchOptions: {
              signal: abortController.signal, // Ajoute le signal d'annulation
            },
          },
        })
        .then((res: any) => {
          setRun({ runningCollection: false, error: "" });

          if (res?.data) {
            setRun({
              collectionResult:
                res?.data?.queryResourcesApi?.response?.products?.map(
                  (p: Dic<any>) => productUtils.selectProductInfos(p, locale)
                ),
            });
          }
        })
        .catch((error: Error) => {
          if (error.name === "AbortError") {
            console.info("Requête annulée");
          } else {
            setRun({
              runningCollection: false,
              collectionResult: [],
              error: error.message,
            });
          }
        });
    }
  };
  return { handleRun, ...run, handleRunPage, handleRunEmail, newHandleRun };
};

export default useRunCollection;
