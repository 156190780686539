import dayjs from "dayjs";
import { objectUtils, stringUtils, filterUtils, arrayUtils } from "@/utils";
import { swap } from "react-grid-dnd";

export class RulesUtils {
  private defaultBlock = {
    rule: {
      switch: [
        [
          true,
          {
            pipe: [],
          },
        ],
      ],
    },
  };

  /**
   * Checks if the given operation is a collection operation.
   *
   * @param operation - The operation to check.
   * @returns `true` if it's a collection operation, `false` otherwise.
   */
  isCollection = (operation: Dic<any>) => {
    try {
      return !!JSON.stringify(operation).includes("apply_recommendation");
    } catch (e: any) {
      return false;
    }
  };

  /**
   * Checks if the given operation is a filter operation.
   *
   * @param operation - The operation to check.
   * @returns `true` if it's a filter operation, `false` otherwise.
   */
  isFilter = (operation: Dic<any>) => {
    try {
      return !!JSON.stringify(operation).includes("filter");
    } catch (e: any) {
      return false;
    }
  };

  /**
   * Checks if the given operation is a promote operation.
   *
   * @param operation - The operation to check.
   * @returns `true` if it's a promote operation, `false` otherwise.
   */
  isPromote = (operation: Dic<any>) => {
    try {
      return !!JSON.stringify(operation).includes("promote");
    } catch (e: any) {
      return false;
    }
  };

  /**
   * Checks if the given operation is a shuffle operation.
   *
   * @param operation - The operation to check.
   * @returns `true` if it's a shuffle operation, `false` otherwise.
   */
  isShuffle = (operation: Dic<any>) => {
    try {
      return !!JSON.stringify(operation).includes("shuffle_on_block_size");
    } catch (e: any) {
      return false;
    }
  };

  /**
   * Retrieves collection operations from the provided array of operations.
   *
   * @param operations - The array of operations.
   * @returns An array containing only collection operations.
   */
  getCollectionOperations = (operations: Array<Dic<any>>) =>
    operations.filter(this.isCollection);

  // ... Similar methods for filter, promote, and shuffle operations ...

  getFilterOperations = (operations: Array<Dic<any>>) =>
    operations.filter(this.isFilter);

  getPromoteOperations = (operations: Array<Dic<any>>) =>
    operations.filter(this.isPromote);

  getShuffleOperations = (operations: Array<Dic<any>>) =>
    operations.filter(this.isShuffle);

  /**
   * Retrieves the indices of various operation types in the given array of operations.
   *
   * @param operations - The array of operations.
   * @returns An object containing indices for different operation types.
   */
  indexOfOperations = (operations: Array<Dic<any>>) => ({
    promote_operations_index:
      operations.findIndex((op: Dic<any>) => op.promote) ?? -1,
    filter_operations_index:
      operations.findIndex((op: Dic<any>) => op.filter) ?? -1,
    collection_operations_index:
      operations.findIndex((op: Dic<any>) => op.apply_recommendation) ?? -1,
    shuffle_operations_index:
      operations.findIndex((op: Dic<any>) => op.shuffle_on_block_size) ?? -1,
  });

  splitOperations = (operations: Array<Dic<any>>) => ({
    promote_operations: this.getPromoteOperations(operations),
    filter_operations: this.getFilterOperations(operations),
    collection_operations: this.getCollectionOperations(operations),
    shuffle_operations: this.getShuffleOperations(operations),
  });

  getCollectionIdFromOperation = (operation: Dic<any>) => {
    try {
      return operation.apply_recommendation[0];
    } catch (e: any) {
      return "";
    }
  };

  removeCollectionAndFilterFromData = (
    operations: Array<Dic<any>>,
    data: Array<any>
  ) => {
    const newOperations = operations.filter(
      (operation: Dic<any>) =>
        !this.isCollection(operation) && !this.isFilter(operation)
    );
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  addCollectionToData = (
    operations: Array<Dic<any>>,
    data: Array<any>,
    defaultRecoId: string
  ) => {
    const newRule = { apply_recommendation: [defaultRecoId] };
    const newOperations = [...operations];
    newOperations.push(newRule);
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  addPromotionToData = (operations: Array<Dic<any>>, data: Array<any>) => {
    const newRule = { promote: [[]] };
    const newOperations = [...operations, newRule];
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  removePromotionFromData = (operations: Array<Dic<any>>, data: Array<any>) => {
    const newOperations = operations.filter(
      (operation: Dic<any>) => !this.isPromote(operation)
    );
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  removeShuffleFromData = (operations: Array<Dic<any>>, data: Array<any>) => {
    const newOperations = operations.filter(
      (operation: Dic<any>) => !this.isShuffle(operation)
    );
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  addShuffleToData = (operations: Array<Dic<any>>, data: Array<any>) => {
    const { collection_operations_index, filter_operations_index } =
      this.indexOfOperations(operations);
    const indexToInsertShuffle =
      filter_operations_index > 0
        ? filter_operations_index
        : collection_operations_index;
    const newRule = { shuffle_on_block_size: [] };
    const newOperations = arrayUtils.insert(
      [...operations],
      indexToInsertShuffle + 1,
      newRule
    );
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  addFilterToData = (
    operations: Array<Dic<any>>,
    data: Array<any>,
    filterType: string
  ) => {
    const newRule = {
      filter: [
        {
          [filterType]: [{ ...filterUtils.getDefaultFilter() }],
        },
      ],
    };
    let newOperations = [...operations];
    newOperations.push(newRule);
    const { promote_operations_index, filter_operations_index } =
      this.indexOfOperations(newOperations);

    if (promote_operations_index !== -1) {
      newOperations = [
        ...swap(
          newOperations,
          promote_operations_index,
          filter_operations_index
        ),
      ];
    }
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  removeFilterFromData = (operations: Array<Dic<any>>, data: Array<any>) => {
    const newOperations = operations.filter(
      (operation: Dic<any>) => !this.isFilter(operation)
    );
    const newData = [...data];
    newData[1] = { pipe: [...newOperations] };
    return newData;
  };

  hasAllRequiredParametersIncludedInDefaultRule = (
    defaultRuleRequiredParameters: Dic<string>,
    ruleRequiredParameters: Dic<string>
  ) => {
    let res = true;
    Object.keys(ruleRequiredParameters).forEach((requiredParamKey: string) => {
      if (!defaultRuleRequiredParameters[requiredParamKey]) res = false;
    });
    return res;
  };

  setPreprocess = (keys: Array<string>) => {
    if (keys.length > 0) {
      return {
        fn: "first_item",
        args: {
          parameters_name: [...keys],
        },
      };
    }
    return {
      fn: "constant",
    };
  };

  getDefaultBloc() {
    return this.defaultBlock;
  }

  getValueFromEntry = (key: string, value: any) => {
    if (key === "var")
      return {
        entry_key: key,
        entry_value: value[0].replace("preprocess.top_keys.", ""),
      };
    return { entry_key: key, entry_value: value };
  };

  /**
   * Retrieves a list of comparison operators for use in CONDITIONAL RULES.
   *
   * @param t - The translation function.
   * @returns An array of objects representing comparison operators.
   */
  getOperators = (t: (text: string) => string): Array<Dic<any>> => {
    return [
      {
        name: "isin",
        description: t("included_in"),
        types: [{ entry: "string", output: "array" }],
        isInverse: false,
      },
      {
        name: "not_isin",
        description: t("not_included_in"),
        types: [{ entry: "string", output: "array" }],
        isInverse: false,
      },
      {
        name: "lt",
        description: t("lower_than"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
      },
      {
        name: "eq",
        description: t("equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
          { entry: "boolean", output: "boolean" },
        ],
        isInverse: false,
      },
      {
        name: "ne",
        description: t("not_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
          { entry: "boolean", output: "boolean" },
        ],
        isInverse: false,
      },
      {
        name: "gt",
        description: t("greater_than"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
      },
      {
        name: "ge",
        description: t("greater_than_or_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
      },
      {
        name: "le",
        description: t("lower_than_or_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "int", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
      },
      {
        name: "anyisin",
        description: t("anyisin"),
        types: [
          { entry: "array", output: "array" },
          { entry: "array_item_id", output: "array" },
          { entry: "item_id", output: "array" },
          { entry: "category_id", output: "array" },
        ],
        isInverse: false,
      },
      {
        name: "not_anyisin",
        description: t("not_anyisin"),
        isInverse: false,
        types: [
          { entry: "array", output: "array" },
          { entry: "array_item_id", output: "array" },
          { entry: "item_id", output: "array" },
          { entry: "category_id", output: "array" },
        ],
      },

      {
        name: "contains",
        description: t("contains"),
        isInverse: false,
        types: [{ entry: "string", output: "string" }],
      },
      {
        name: "not_contains",
        description: t("does_not_contains"),
        isInverse: false,
        types: [{ entry: "string", output: "string" }],
      },
    ];
  };

  /**
   * Decomposes a clause into its constituent parts.
   *
   * @param clause - The clause to decompose.
   * @returns An object representing the decomposed clause.
   */
  decomposeClause = (clause: Dic<any>) => {
    let res: Dic<any> = {
      entry: {
        type: "", // most_frequent_key or constant
        value: "",
        key: "",
        index: 0,
      },
      operator: {
        name: "",
      },
      output: {
        value: "",
        index: 0,
      },
    };
    try {
      const [operationName, value] = Object.entries(clause)[0];
      res.operator.name = operationName;

      value.forEach((v: any, index: number) => {
        if (typeof v === "object" && !Array.isArray(v)) {
          const [entryKey, entryValue]: any = Object.entries(v)[0];
          res = {
            ...res,
            entry: {
              type: entryKey,
              value: entryValue[1].get_details_parameter[0],
              key: entryValue[0],
              index,
            },
          };
        } else {
          res = {
            ...res,
            output: {
              index,
              value: v,
            },
          };
        }
      });
    } catch (e: any) {}

    return res;
  };

  /**
   * Add fields "all" into rule settings
   *
   * @param settings - The settings of the rule.
   * @returns The settings with fields: "all" added
   */
  formatSettings = (settings: Dic<any>) => {
    if (!settings) return {};
    return {
      ...settings,
      settings: { ...settings.settings, fields: "all" },
    };
  };

  /**
   * Retrieves the content of a collection based on its type and settings.
   *
   * @param type - The type of the collection.
   * @param settings - The settings of the collection.
   * @param t - The translation function.
   * @returns The content of the collection as a string.
   */
  getCollectionContent =
    (type: string, settings: Dic<any>) => (t: (text: string) => string) => {
      if (type === "dynamic") {
        return settings?.algorithm_name ?? "";
      }

      if (type === "composed") {
        return settings.composed_collections
          ?.map((c: Dic<any>) => t(c?.name))
          .join(", ");
      }

      return settings?.products
        ?.map((product: Dic<any>) => {
          if (typeof product?.title === "object") {
            return objectUtils.getFirstObjectValue(product?.title);
          }
          return product?.title;
        })
        .join(", ");
    };

  getCollectionType = (item: any) => {
    let type;
    try {
      if (item.node.settings.composed_collections) {
        type = "composed";
      } else if (item.node.settings.blocks) {
        type = "conditional";
      } else if (
        item.node.settings.algorithm ||
        item.node.settings.required_parameters
      ) {
        type = "dynamic";
      } else {
        type = "manual";
      }
    } catch (e: any) {
      type = "";
    }

    return type;
  };

  /**
   * Retrieves data for a collection item.
   *
   * @param item - The collection item.
   * @param t - The translation function.
   * @param rest - Additional parameters.
   * @returns An object containing data for the collection item.
   */
  getCollectionData = (
    item: Dic<any>,
    t: (text: string) => string,
    ...rest: any[]
  ) => ({
    ...item?.node,
    isBuiltin: !!item?.node?.isBuiltin,
    id: parseInt(item?.node?.id ?? "0", 10),
    name: t(item?.node?.name),
    type: stringUtils.capitalizeFirstLetter(this.getCollectionType(item)),
    displayType: t(this.getCollectionType(item)),
    content: this.getCollectionContent(
      this.getCollectionType(item),
      item?.node?.settings
    )(t),
    ready: !!item?.node?.lastUpdate,
    lastUpdate: item?.node?.lastUpdate
      ? new Date(item?.node?.lastUpdate).toUTCString()
      : "-",
    initial: item?.node?.initial,
    canDelete: !item?.node?.initial,
    usedIn: item?.node?.settings?.used_in?.length > 0,
    parameter: item?.node?.settings?.required_parameter,
    createdAt: item?.node?.createdAt
      ? dayjs(item?.node?.createdAt)?.format("LLL")
      : null,
    get edit(): string {
      return `collections/${this.type.toLowerCase()}_collection/${this.id}`;
    },
    createdBy:
      item?.node?.createdByUser?.email &&
      !item?.node?.createdByUser?.email?.includes("@get-potions.com")
        ? item?.node?.createdByUser?.email
        : "Potions",
    variables: item?.node?.variables?.map((variable: Dic<any>) => ({
      variableId: variable?.variableId,
      inputParameters: variable?.inputParameters,
    })),
    ...rest,
  });

  getExecutionParameter = (requiredParameter: string | null) => {
    if (
      requiredParameter &&
      [
        "product_id",
        "product_ids",
        "category",
        "category|gender",
        "gender",
        "domain",
      ].includes(requiredParameter)
    )
      return "product_ids";

    return requiredParameter;
  };

  getPreprocess = (algorithmParameter: any, collectionParameter: any) => {
    let preprocess: Dic<any> | null = {
      fn: "constant",
      args: {
        key_type: "",
      },
    };
    if (!algorithmParameter) return preprocess;
    switch (algorithmParameter) {
      case "product_id":
        preprocess = {
          fn: "first_id",
          args: {
            key_type: "",
          },
        };
        break;
      case "category_id":
        if (collectionParameter === "category_id") preprocess = null;
        else
          preprocess = {
            fn: "most_frequent",
            args: {
              key_type: algorithmParameter,
            },
          };
        break;

      default:
        preprocess = {
          fn: "most_frequent",
          args: {
            key_type: algorithmParameter,
          },
        };
        break;
    }
    return preprocess;
  };
}
