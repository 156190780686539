import { Error, Layer1Box, Text } from "@/includes";
import { Box, Grid, Stack } from "@mui/material";
import Tendency from "../../../Tendency";
import { useTranslation } from "react-i18next";
import { EngagementMeasureVisualizationProps } from "./types";
import { formatUtils } from "@/utils";
import { StatusEnum } from "@/utils/helpers/status/enums";

const Visualization = ({
  header,
  newValue,
  oldValue,
  compare,
  compareValue,
  status,
  statusCompare,
  locale,
}: EngagementMeasureVisualizationProps) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Box sx={{ width: 1, height: 1 }}>
        <Layer1Box
          sx={{
            transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
            position: "relative",
            height: "calc(100% - 24px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Text variant="body1">{t(`number_of_${header}`)}</Text>
          {status === StatusEnum.ERROR || statusCompare === StatusEnum.ERROR ? (
            <Error />
          ) : (
            <Stack
              direction="row"
              justifyContent={compare ? "flex-start" : "space-between"}
              alignItems={compare ? "baseline" : "center"}
              sx={{ mt: 1 }}
            >
              <Text variant="h2">
                {formatUtils.formatInt(newValue, locale)}
              </Text>
              {compare && (
                <Text variant="body1" customStyle={{ mx: 1 }}>
                  vs
                </Text>
              )}
              {compare && (
                <Text variant="h2">
                  {formatUtils.formatInt(compareValue ?? 0, locale)}
                </Text>
              )}
              {!compare && <Tendency newValue={newValue} oldValue={oldValue} />}
            </Stack>
          )}
        </Layer1Box>
      </Box>
    </Grid>
  );
};

export default Visualization;
