import { useSelector } from "@xstate/react";
import { useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import { formatUtils } from "@/utils";
import { Text, Stack, Box, Loader, Layer1Box, Layer2Box, Tag } from "@includes";
import { useReport } from "../../hooks/useReport";
import { useSetState, useIsMounted } from "@hooks";
import { ISynthesis } from "./types";
import { animationConfig } from "./enums";
import { useLocale } from "@/context/LocaleProvider";

const allUsersIndicatorsSelector = (state: any) =>
  state.context.allUsersIndicators;

const loadingSelector = (state: any) => state.context.loading;

const usersIndicatorsSelector = (state: any) => state.context.usersIndicators;

const errorSelector = (state: any) => state.context.error;

const Synthesis: React.FC<ISynthesis> = ({
  experienceName,
  isConnect,
  locale = "fr",
  currency = "€",
}) => {
  const isMounted = useIsMounted();
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const reportServices = useReport();

  const error = useSelector(reportServices.reportService, errorSelector);

  const loading = useSelector(reportServices.reportService, loadingSelector);

  const allUsersIndicators = useSelector(
    reportServices.reportService,
    allUsersIndicatorsSelector
  );

  const usersIndicators = useSelector(
    reportServices.reportService,
    usersIndicatorsSelector
  );

  const [syntheseData, setSyntheseData] = useSetState({
    used: null,
    nbUsed: 0,
    revenueGeneratedByUserUsingThisExperience: null,
    usedRevenueRatio: null,
  });

  const setDatas = () => {
    if (isConnect) {
      const allUserTransactionRevenue =
        allUsersIndicators?.transactionRevenue / allUsersIndicators?.users;
      setSyntheseData({
        used: usersIndicators?.used?.users / allUsersIndicators?.users,
        nbUsed: usersIndicators?.used?.users,
        revenueGeneratedByUserUsingThisExperience:
          usersIndicators?.used?.transactionRevenue /
          allUsersIndicators?.transactionRevenue,
        revenue: usersIndicators?.used?.transactionRevenue,
        usedRevenueRatio:
          usersIndicators?.usedBuyers?.transactionRevenue /
          usersIndicators?.used?.users /
          allUserTransactionRevenue,
        revenuePerUser:
          usersIndicators?.usedBuyers?.transactionRevenue /
          usersIndicators?.used?.users,
      });
    } else {
      setSyntheseData({
        used: usersIndicators?.used?.users / usersIndicators?.seen?.users,
        nbUsed: usersIndicators?.used?.users,
        revenueGeneratedByUserUsingThisExperience:
          usersIndicators?.used?.transactionRevenue /
          allUsersIndicators?.transactionRevenue,
        revenue: usersIndicators?.used?.transactionRevenue,
        usedRevenueRatio:
          usersIndicators?.usedBuyers?.transactionRevenue /
          usersIndicators?.used?.users /
          (usersIndicators?.seenBuyers?.transactionRevenue /
            usersIndicators?.seen?.users),
        revenuePerUser:
          usersIndicators?.usedBuyers?.transactionRevenue /
          usersIndicators?.used?.users,
      });
    }
  };

  useEffect(() => {
    if (usersIndicators && allUsersIndicators && isMounted) {
      setDatas();
    }
  }, [usersIndicators, allUsersIndicators]);

  // Animations -> Slide in from right to left on by one

  const usedProps = useSpring({
    opacity: syntheseData.used ? 1 : 0,
    marginLeft: syntheseData.used ? 0 : 1500,
    config: {
      ...animationConfig,
    },
  });

  const revenueGeneratedByUserUsingThisExperienceProps = useSpring({
    opacity: syntheseData.used ? 1 : 0,
    marginLeft: syntheseData.used ? 0 : 1500,
    config: {
      ...animationConfig,
    },
    delay: 375,
  });

  const usedRevenueRatioProps = useSpring({
    opacity: syntheseData.used ? 1 : 0,
    marginLeft: syntheseData.used ? 0 : 1500,
    config: {
      ...animationConfig,
    },
    delay: 725,
  });

  const AnimatedBox = animated(Box);

  if (!error && loading) return <Loader />;

  return (
    <Stack spacing={2} sx={{ height: "100%" }}>
      <Layer1Box>
        <Stack spacing={1} justifyContent="start">
          <Text variant="subtitle1">
            {t("visitors_used")} {experienceName} {t("overview")}
          </Text>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <AnimatedBox
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    boxSizing: "border-box",
                    ...usedProps,
                    maxHeight: 170,
                  }}
                >
                  <Layer2Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ height: "100%" }}
                    >
                      <Stack spacing={2} alignItems="flex-start">
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box
                            sx={{ width: 48 }}
                            component="img"
                            alt="users"
                            src={`/${theme.customPalette.mode}/report/synthese/use.svg`}
                          />
                          <Text variant="subtitle1">{t("number_users")}</Text>
                        </Stack>

                        <Stack
                          direction="row"
                          sx={{ gap: 2 }}
                          alignItems="flex-start"
                        >
                          <Text variant="h2">{syntheseData.nbUsed}</Text>
                          <Tag tagVariant="primary_border">
                            {formatUtils.formatToPercent(syntheseData?.used)}
                          </Tag>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Layer2Box>
                </AnimatedBox>
              </Grid>
              <Grid item md={4} xs={12}>
                <AnimatedBox
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    maxHeight: 170,
                    boxSizing: "border-box",
                    ...revenueGeneratedByUserUsingThisExperienceProps,
                  }}
                >
                  <Layer2Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ height: "100%" }}
                    >
                      <Stack spacing={2} alignItems="flex-start">
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box
                            sx={{ width: 48 }}
                            component="img"
                            alt="users"
                            src={`/${theme.customPalette.mode}/report/synthese/expenses.svg`}
                          />
                          <Text variant="subtitle1">{t("revenue")}</Text>
                        </Stack>

                        <Stack direction="row" sx={{ gap: 2 }}>
                          <Text variant="h2">
                            {formatUtils.formatIntegerCurrency(
                              syntheseData.revenue,
                              locale,
                              currency
                            )}
                          </Text>
                          <Tag tagVariant="tertiary_border">
                            {formatUtils.formatToPercent(
                              syntheseData?.revenueGeneratedByUserUsingThisExperience
                            )}
                          </Tag>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Layer2Box>
                </AnimatedBox>
              </Grid>
              <Grid item md={4} xs={12}>
                <AnimatedBox
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    maxHeight: 170,
                    boxSizing: "border-box",
                    ...usedRevenueRatioProps,
                  }}
                >
                  <Layer2Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ height: "100%" }}
                    >
                      <Stack spacing={2} alignItems="flex-start">
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box
                            sx={{ width: 48 }}
                            component="img"
                            alt="users"
                            src={`/${theme.customPalette.mode}/report/synthese/revenue.svg`}
                          />
                          <Text variant="subtitle1">
                            {t("revenue_per_user")}
                          </Text>
                        </Stack>

                        <Stack direction="row" sx={{ gap: 2 }}>
                          <Text variant="h2">
                            {formatUtils.formatCurrency(
                              syntheseData.revenuePerUser,
                              locale,
                              currency
                            )}
                          </Text>
                          <Tag tagVariant="secondary_border">
                            {formatUtils.formatMultiply(
                              syntheseData?.usedRevenueRatio,
                              1
                            )}
                          </Tag>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Layer2Box>
                </AnimatedBox>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Layer1Box>
    </Stack>
  );
};

export default Synthesis;
