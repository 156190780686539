import Tendency from "@/components/_Organisms/Tendency";
import { Error, Layer1Box, Text } from "@/includes";
import { Stack, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OverviewVisualizationProps } from "./types";
import { isNil } from "lodash";
import { HOME_OVERVIEW_LABEL_ENUMS } from "../../enums";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { getValueByFormat } from "./helpers";

const OverviewVisualization = ({
  newValue,
  oldValue,
  format,
  overviewSelected,
  setOverviewSelected,
  overviewKey,
  loading,
  locale = "fr",
  currency = "€",
}: OverviewVisualizationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSelected = overviewSelected === overviewKey;
  return (
    <Box
      sx={{ width: 1, flex: 1 }}
      onClick={() => setOverviewSelected(overviewKey)}
    >
      <Layer1Box
        sx={{
          height: "100%",
          transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background: isSelected
            ? theme.customPalette.bluePurple.lighter
            : theme.customPalette.white.main,
          borderColor: isSelected
            ? theme.customPalette.bluePurple.dark
            : theme.customPalette.grey.lighter,
          "&:hover": {
            background: isSelected
              ? theme.customPalette.bluePurple.lighter
              : theme.customPalette.grey.borderHome,
          },
          cursor: "pointer",
        }}
      >
        <Text variant="body1" customStyle={{ width: 1 }}>
          {t(`${HOME_OVERVIEW_LABEL_ENUMS[overviewKey]}`)}
        </Text>
        {loading === StatusEnum.ERROR ? (
          <Error />
        ) : (
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ mt: 1, width: 1 }}
          >
            <Text variant="h2">
              {!isNil(format)
                ? getValueByFormat(format, locale, currency, newValue)
                : newValue}
            </Text>
            <Tendency newValue={newValue} oldValue={oldValue} />
          </Stack>
        )}
      </Layer1Box>
    </Box>
  );
};

export default OverviewVisualization;
